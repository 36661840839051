

































import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { fromWei, getChainId, toWei, toBN } from '~/utils/util'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import { 
    getAddressOfContract, 
    ContractNames
} from '~/consts/config'
import { ListMode, PopupMode } from './models'

@Component({
  components: {
  },
})
export default class BidItemForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  isAllowanceApproved: boolean = true

  checkAllowance: () => void

  created() {
    this.checkAllowance = _debounce(() => {
      this._onCheckAllowance()
    }, 300)
  }

  async onCheckAllowance() {
    const form = this.form
    if (!form.bidPrice || form.bidPrice.length == 0) {
      this.isAllowanceApproved = true
      return
    }

    this.allowanceOfERC20TokenParams.tokenName = 'BUSD'
    this.allowanceOfERC20TokenParams.contractAddr = getAddressOfContract(ContractNames.SecondaryMarket)
    const getAllowance = this.allowanceOfERC20Token
    await getAllowance.call()

    const res = `${getAllowance.callItems[0]}`
    const allowance = toBN(res)
    this.isAllowanceApproved = allowance.gte(toWei(form.bidPrice))
  }

  async _onCheckAllowance() {
    this.onCheckAllowance()
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: any, oldVal: any) {
    this.checkAllowance()
  }

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    if (!this.isAllowanceApproved) {
      this.beginTransaction()
      this.approveERC20TokenParams.tokenName = 'BUSD'
      this.approveERC20TokenParams.approveContractAddr = getAddressOfContract(ContractNames.SecondaryMarket)
      this.approveERC20TokenParams.success = this.endTransactionApprove
      this.approveERC20TokenParams.err = () => { this.endContractTransactionError(this.approveERC20Token) }
      this.approveERC20Token.call()
    } else {
      this.onBidItem(value)
    }
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransactionApprove() {
    this.isLoading = false
    this.checkAllowance()
    this.onBidItem(this.form)
  }

  endTransactionBuy() {

    this.isLoading = false

    if (this.bidItem.callItems.length > 0) {
      const blockNumber = this.bidItem.callItems[0].blockNumber

      const reloadMyItems = async () => {
        this.myItemsRefreshParams.blockNumber = blockNumber
        await this.myItemsRefresh.call()
        this.myItemsLoader.call()
      }
      reloadMyItems()
    }
    
    this.balanceOfCityToken.call()
    
    this.onCloseFormClick()
    this.popupMode = PopupMode.None
    this.listMode = ListMode.MyAssetList
  }

  onBidItem(value: FormData) {
    this.beginTransaction()
    this.bidItemParams.landId = value.landId
    this.bidItemParams.itemName = value.bidItemName
    this.bidItemParams.itemType = value.bidItemType
    this.bidItemParams.itemContentUrl = value.bidItemContentUrl
    this.bidItemParams.buyPrice = value.bidPrice
    this.bidItemParams.expiredInNDays = Number(value.bidExpiredInNDays)
    this.bidItemParams.success = this.endTransactionBuy
    this.bidItemParams.err = () => { this.endContractTransactionError(this.bidItem) }
    this.bidItem.call()
  }

  get selectedNFTItemName(): string {
    return this.formRepo.getAttr(this.form_name, 'bidItemName')
  }

  get selectedNFTItemType(): number {
    return this.formRepo.getAttr(this.form_name, 'bidItemType')
  }

  get selectedNFTUrl(): string {
    return this.formRepo.getAttr(this.form_name, 'bidItemContentUrl')
  }

  get bidItemFormOptions(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'landId',
          label: 'Creator Land Id',
          rules: [RuleHelper.required],
          disabled: true,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'bidItemName',
          label: 'Item Name',
          rules: [RuleHelper.required],
          disabled: true,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'bidItemType',
          label: 'Item Type',
          rules: [RuleHelper.required],
          disabled: true,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'bidItemContentUrl',
          label: 'NFT URL',
          rules: [RuleHelper.required],
          disabled: true,
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'bidExpiredInNDays',
          label: 'Bid Expired In (Days)',
          placeholder: '',
          className: 'select-expired-days',
          rules: [RuleHelper.required],
          defaultValue: '365',
          options: this.expiredNDaysOptions,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '6',
        props: {
          name: 'bidPrice',
          label: 'Bid Price',
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '6',
        props: {
          name: 'bidWithToken',
          placeholder: '',
          defaultValue: 'BUSD',
          className: 'select-token',
          disabled: true,
          rules: [RuleHelper.required],
          options: [
            {
              value: 'BUSD',
              label: 'BUSD'
            }
          ]
        }
      },
    ]
  }
}

interface FormData {
  landId: string
  bidItemName: string
  bidItemType: number
  bidExpiredInNDays: string
  bidItemContentUrl: string
  bidItemAutonumber: number
  bidPrice: string
}
