



























import { Component, Provide, Watch } from 'vue-property-decorator'
import { BaseApp } from '~/core/BaseApp'

@Component({
  components: {
  },
})
export default class Footer extends BaseApp {

  footerStyle: {[key: string]: string} = {}

  mounted() {
    this.footerStyle = {
      'visibility': 'visible'
    }
  }

  created() {
    this.footerStyle = {
      'visibility': 'hidden'
    }
  }
}
