























































































import { Component, Provide, Watch } from 'vue-property-decorator'
import { 
  CitizenInfo,
  newCitizenInfo, 
} from '~/types/meta_city'
import { CityBaseForm } from './CityBaseForm'
import { 
  CityCell,
  newCityCell,
  FormMode,
  ListMode,
  PopOverItemType,
  PopOverMode
} from './models'
import { MetaCityHelper } from '~/utils/MetaCityHelper'
import { DefaultAvatarURL } from '~/consts/config'
import { fromWei, toBN, toRounded } from '~/utils/util'
import BN from 'bn.js'

@Component({
  components: {
  },
})
export default class BusinessList extends CityBaseForm<any> {
  @Provide('form_name') form_name = 'city_form'

  get citizen(): CitizenInfo {
    const citizenInfo = this.citizenInfo
    if (citizenInfo.callItems.length > 0) {
      return citizenInfo.callItems[0]
    }
    return newCitizenInfo()
  }

  get myBuildingCells(): CityCell[] {
    return this.cityGrid.cells.filter((cell: CityCell) => {
      return cell.building_type.length > 0 && cell.is_business && cell.owner_addr == this.walletAddress
    })
  }

  get otherBuildingCells(): CityCell[] {
    return this.cityGrid.cells.filter((cell: CityCell) => {
      return cell.building_type.length > 0 && cell.is_business && cell.owner_addr != this.walletAddress
    })
  }

  get numberOfMyNFTItems(): string {
    return `${this.myItemsLoader.callItems.length}`
  }

  onOwnerAvatarClick() {
    if (!this.citizen) {
      return
    }

    const citizen = this.citizen
    this.popOverItem = {
      item_id: `${citizen.avatar_item_id}`,
      item_type: PopOverItemType.Avatar,
      item_name: citizen.name,
      explain: '',
      item_content_url: citizen.avatar_content_url,
      building_type: 0,
    }
    this.popOverMode = PopOverMode.ItemDetail
  }

  @Watch('selectedBusinessCell')
  onSelectedBusinessCellChanged() {
    const landId = `${this.selectedBusinessCell.id}`
    this.formRepo.updateAttr(this.form_name, 'landId', landId)

    this.listItemsParams.landId = landId
    this.listItemsParams.fromAutonumber = 0
    this.listItems.call()
    this.listItemsCurrentAutonumber.call()
  }

  onViewMyNFTClick() {
    this.listMode = ListMode.MyAssetList
  }

  onViewMetaTweetsClick() {
    this.listTweetsParams.fromAutonumber = 0
    this.listTweets.call()
    this.listTweetsLastAutonumber.call()
    this.listMode = ListMode.TweetList
  }

  onPostMetaTweetClick() {
    this.myBusinessLoader.call()
    this.formMode = FormMode.TweetForm
  }

  onUpdateMyAvatarClick() {
    this.formRepo.updateAttr(this.form_name, 'citizenName', this.citizen.name)
    this.formRepo.updateAttr(this.form_name, 'occupation', this.citizen.occupation)
    
    this.formMode = FormMode.RegisterCitizenForm
  }

  onBusinessItemClick(cell: CityCell) {
    this.selectedBusinessCell = cell
    this.listMode = ListMode.BusinessDetail

    // Load business owner
    this.businessOwnerInfoParams.ownerAddr = cell.owner_addr
    this.businessOwnerInfo.call()
  }

  onBusinessItemMouseEnter(cell: CityCell) {
    this.hoverCell = cell
  }

  onBusinessItemMouseLeave(cell: CityCell) {
    this.hoverCell= newCityCell()
  }

  calYourAvatarClass(): {[key: string]: boolean} {
    const classes = {
      'avatar-row': true
    }
    if (this.listMode == ListMode.MyAssetList) {
      classes['active'] = true
    }
    return classes
  }

  calBusinessItemClass(item: CityCell): {[key: string]: boolean} {
    const classes = {
      'business-row': true,
    }
    if (item.id == this.selectedBusinessCell.id && this.listMode == ListMode.BusinessDetail) {
      classes['active'] = true
    }
    if (item.id == this.hoverCell.id) {
       classes['active'] = true
    }
    return classes
  }
}
