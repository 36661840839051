




































import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { fromWei, getChainId, toWei, toBN } from '~/utils/util'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import {
  FormMode,
  BusinessType,
  Occupation,
} from './models'
import { 
    getAddressOfContract, 
    ContractNames
} from '~/consts/config'

@Component({
  components: {
  },
})
export default class RegisterCitizenForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  isAllowanceApproved: boolean = true

  checkAllowance: () => void

  created() {
    this.checkAllowance = _debounce(() => {
      this._onCheckAllowance()
    }, 300)
  }

  async onCheckAllowance() {
    const form = this.form
    if (!form.registerFee || form.registerFee.length == 0) {
      this.isAllowanceApproved = true
      return
    }

    this.allowanceOfERC20TokenParams.tokenName = 'BUSD'
    this.allowanceOfERC20TokenParams.contractAddr = getAddressOfContract(ContractNames.MetaCity)
    const getAllowance = this.allowanceOfERC20Token
    await getAllowance.call()

    const res = `${getAllowance.callItems[0]}`
    const allowance = toBN(res)
    this.isAllowanceApproved = allowance.gte(toWei(form.registerFee))
  }

  async _onCheckAllowance() {
    this.onCheckAllowance()
  }

  get selectedNFTUrl(): string {
    return this.formRepo.getAttr(this.form_name, 'avatarItemContentUrl')
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: any, oldVal: any) {
    this.checkAllowance()
  }

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    if (!this.isAllowanceApproved) {
      this.beginTransaction()
      this.approveERC20TokenParams.tokenName = 'BUSD'
      this.approveERC20TokenParams.approveContractAddr = getAddressOfContract(ContractNames.MetaCity)
      this.approveERC20TokenParams.success = this.endTransactionApprove
      this.approveERC20TokenParams.err = () => { this.endContractTransactionError(this.approveERC20Token) }
      this.approveERC20Token.call()
    } else {
      this.onRegisterCitizen(value)
    }
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransactionApprove() {
    this.isLoading = false
    this.checkAllowance()

    // Send transaction after approve
    this.onRegisterCitizen(this.form)
  }

  onRegisterCitizen(value: FormData) {
    this.beginTransaction()
    this.registerCitizenParams.fee = value.registerFee
    this.registerCitizenParams.name = value.citizenName
    this.registerCitizenParams.occupation = value.occupation
    this.registerCitizenParams.avatarItemId = value.avatarItemId
    this.registerCitizenParams.success = this.endTransactionRegister
    this.registerCitizenParams.err = () => { this.endContractTransactionError(this.registerCitizen) }
    this.registerCitizen.call()
  }

  endTransactionRegister() {
    this.isLoading = false
    this.citizenInfo.call()
    this.checkAllowance()
    this.balanceOfCityToken.call()
    this.onCloseFormClick()
  }

  get registerCitizenFormOptions(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'citizenName',
          label: 'My Name',
          rules: [RuleHelper.required]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'occupation',
          label: 'My Occupation',
          placeholder: '',
          className: 'select-occupation',
          rules: [RuleHelper.required],
          options: this.allOccupations,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '6',
        props: {
          name: 'registerFee',
          label: 'City Fee',
          defaultValue: '1',
          disabled: true,
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '6',
        props: {
          name: 'registerWithToken',
          placeholder: '',
          defaultValue: 'BUSD',
          className: 'select-token',
          disabled: true,
          rules: [RuleHelper.required],
          options: [
            {
              value: 'BUSD',
              label: 'BUSD'
            }
          ]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'avatarItemId',
          label: 'My Avatar Image (NFT)',
          explain: 'Buy NFT from seller in MetaCity to use as your avatar',
          placeholder: '',
          defaultValue: '',
          className: 'select-item',
          options: this.avatarNFTOptionItems,
          on: {
            change: (newVal: any) => {
              for (let i=0; i<this.myItemsLoader.callItems.length; i++) {
                const item = this.myItemsLoader.callItems[i]
                if (item.item_id == newVal) {
                  this.formRepo.updateAttr(this.form_name, 'avatarItemContentUrl', item.item_content_url)
                  break
                }
              }
            }
          }
        }
      },
    ]
  }

  get avatarNFTOptionItems(): IOption[] {
    const items: IOption[] = []
    for (let i=0; i<this.myItemsLoader.callItems.length; i++) {
      const item = this.myItemsLoader.callItems[i]
      items.push({
        value: item.item_id,
        label: item.item_name,
      })
    }
    return items
  }

  // Programmer = 1
  // Designer = 2
  // Marketer = 3
  // Creative = 4
  // Business = 5
  // PropertyDeveloper = 6
  get allOccupations(): IOption[] {
    return [
      {
        value: Occupation.Programmer,
        label: 'Programmer'
      },
      {
        value: Occupation.Designer,
        label: 'Designer'
      },
      {
        value: Occupation.Marketer,
        label: 'Marketer'
      },
      {
        value: Occupation.Creative,
        label: 'Creative'
      },
      {
        value: Occupation.Business,
        label: 'Business Person'
      },
      {
        value: Occupation.PropertyDeveloper,
        label: 'Property Developer'
      },
    ]
  }
}

interface FormData {
  citizenName: string
  occupation: number
  registerFee: string
  registerWithToken: string
  avatarItemId: string
}
