




























import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import { fromWei, getChainId, toWei, toBN } from '~/utils/util'
import { 
    getAddressOfContract, 
    ContractNames
} from '~/consts/config'
import { FormMode, ListMode } from './models'

@Component({
  components: {
  },
})
export default class BuyBusinessForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  isAllowanceApproved: boolean = true

  checkAllowance: () => void

  created() {
    this.checkAllowance = _debounce(() => {
      this._onCheckAllowance()
    }, 300)
  }

  async onCheckAllowance() {
    const form = this.form
    if (!form.businessBuyPrice || form.businessBuyPrice.length == 0) {
      this.isAllowanceApproved = true
      return
    }

    this.allowanceOfERC20TokenParams.tokenName = 'BUSD'
    this.allowanceOfERC20TokenParams.contractAddr = getAddressOfContract(ContractNames.MetaMarket)
    const getAllowance = this.allowanceOfERC20Token
    await getAllowance.call()

    const res = `${getAllowance.callItems[0]}`
    const allowance = toBN(res)
    this.isAllowanceApproved = allowance.gte(toWei(form.businessBuyPrice))
  }

  async _onCheckAllowance() {
    this.onCheckAllowance()
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: any, oldVal: any) {
    this.checkAllowance()
  }

  get actionTitle(): string {
    if (this.formMode == FormMode.BuyBusinessForm) {
      return 'Buy This Land'
    } else {
      // FormMode.OfferBuyBusinessForm
      return 'Place Bid to Buy This Land'
    }
  }

  get approveActionTitle(): string {
    if (this.formMode == FormMode.BuyBusinessForm) {
      return 'Approve to Buy This Land'
    } else {
      // FormMode.OfferBuyBusinessForm
      return 'Approve Bid to Buy This Land'
    }
  }

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    if (!this.isAllowanceApproved) {
      this.beginTransaction()
      this.approveERC20TokenParams.tokenName = 'BUSD'
      this.approveERC20TokenParams.approveContractAddr = getAddressOfContract(ContractNames.MetaCity)
      this.approveERC20TokenParams.success = this.endTransactionApprove
      this.approveERC20TokenParams.err = () => { this.endContractTransactionError(this.approveERC20Token) }
      this.approveERC20Token.call()
    } else {
      this.onCreateBuyOrder(value)
    }
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransactionApprove() {
    this.isLoading = false
    this.onCreateBuyOrder(this.form)
  }

  endTransactionBuy() {
    this.isLoading = false
    
    this.balanceOfCityToken.call()
    this.onCloseFormClick()

    const reloadCityGrid = async () => {
      const formData = this.form
      this.cityCellRefreshParams.landId = `${formData.businessLandId}`
      await this.cityCellRefresh.call()
      this.cityGridLoader.call()
    }
    reloadCityGrid()

    this.listMode = ListMode.None
  }

  onCreateBuyOrder(value: FormData) {
    this.beginTransaction()
    this.createBuyOrderParams.landId = value.businessLandId
    this.createBuyOrderParams.buyPrice = value.businessBuyPrice
    this.createBuyOrderParams.expiredInNDays = Number(value.buyOrderExpiredInNDays)
    this.createBuyOrderParams.newBusinessName = value.newBusinessName
    this.createBuyOrderParams.newBusinessType = value.newBusinessType
    this.createBuyOrderParams.success = this.endTransactionBuy
    this.createBuyOrderParams.err = () => { this.endContractTransactionError(this.createBuyOrder) }
    this.createBuyOrder.call()
  }

  get buyBusinessFormOptions(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'businessLandId',
          label: 'Land Id',
          disabled: true,
          defaultValue: this.selectedBusinessCell.id,
          rules: [RuleHelper.required]
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '6',
        props: {
          name: 'businessBuyPrice',
          label: 'Buy Price',
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '6',
        props: {
          name: 'buyBusinessWithToken',
          placeholder: '',
          defaultValue: 'BUSD',
          className: 'select-token',
          disabled: true,
          rules: [RuleHelper.required],
          options: [
            {
              value: 'BUSD',
              label: 'BUSD'
            }
          ]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'buyOrderExpiredInNDays',
          label: 'Offer Expired In (Days)',
          placeholder: '',
          className: 'select-expired-days',
          rules: [RuleHelper.required],
          defaultValue: '365',
          options: this.expiredNDaysOptions,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'newBusinessName',
          label: 'My Brand',
          defaultValue: '',
          rules: [RuleHelper.required]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'newBusinessType',
          label: 'My Business',
          placeholder: '',
          className: 'select-business-type',
          rules: [RuleHelper.required],
          defaultValue: '',
          options: this.allBusinessTypes,
        }
      },
    ]
  }
}

interface FormData {
  businessLandId: number
  businessBuyPrice: string
  buyBusinessWithToken: string
  buyOrderExpiredInNDays: string
  newBusinessName: string
  newBusinessType: number
}

