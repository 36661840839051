import { zeroAddr } from "~/utils/util"

export interface BidItemParams {
  landId: string,
  itemName: string,
  itemType: number,
  itemContentUrl: string
  buyPrice: string
  expiredInNDays: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newBidItemParams = (): BidItemParams => {
  return {
    landId: '',
    itemName: '',
    itemType: 0,
    itemContentUrl: '',
    buyPrice: '',
    expiredInNDays: 0,
    success: null,
    err: null,
  }
}

export interface PurchaseHistoryParams {
  landId: string
}

export const newPurchaseHistoryParams = (): PurchaseHistoryParams => {
  return {
    landId: ''
  }
}

export interface MyItemsRefreshParams {
  blockNumber: number
}

export const newMyItemsRefreshParams = (): MyItemsRefreshParams => {
  return {
    blockNumber: 0
  }
}

export interface CityCellRefreshParams {
  landId: string
}

export const newCityCellRefreshParams = (): CityCellRefreshParams => {
  return {
    landId: ''
  }
}

export interface MySellingItemsParams {
  landId: string
  fromAutonumber: number
}

export const newMySellingItemsParams = (): MySellingItemsParams => {
  return {
    landId: '',
    fromAutonumber: 0,
  }
}

export interface ListTweetsParams {
  fromAutonumber: number
}

export const newListTweetsParams = (): ListTweetsParams => {
  return {
    fromAutonumber: 0,
  }
}

export interface PostTweetParams {
  message: string
  landId: string
  itemId: string
  sellId: string
  toAddress: string
  fee: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newPostTweetParams = (): PostTweetParams => {
  return {
    message: '',
    landId: '0',
    itemId: '0',
    sellId: '0',
    fee: '1',
    toAddress: zeroAddr,
    success: null,
    err: null,
  }
}

export interface BusinessOwnerInfoParams {
  ownerAddr: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newBusinessOwnerInfoParams = (): BusinessOwnerInfoParams => {
  return {
    ownerAddr: '',
    success: null,
    err: null,
  }
}

export interface ApproveMetaItemForSecondaryMarketParams {
  itemId: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newApproveMetaItemForSecondaryMarketParams = (): ApproveMetaItemForSecondaryMarketParams => {
  return {
    itemId: 0,
    success: null,
    err: null,
  }
}

export interface AllowanceOfMetaItemForSecondaryMarketParams {
  itemId: number
}

export const newAllowanceOfMetaItemForSecondaryMarketParams = (): AllowanceOfMetaItemForSecondaryMarketParams => {
  return {
    itemId: 0
  }
}

export interface ApproveMetaLandForMetaCityParams {
  landId: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newApproveMetaLandForMetaCityParams = (): ApproveMetaLandForMetaCityParams => {
  return {
    landId: 0,
    success: null,
    err: null,
  }
}

export interface AllowanceOfMetaLandForMetaCityParams {
  landId: number
}

export const newAllowanceOfMetaLandForMetaCityParams = (): AllowanceOfMetaLandForMetaCityParams => {
  return {
    landId: 0
  }
}

export interface CreateBuyOrderParams {
  buyPrice: string
  landId: number
  expiredInNDays: number
  newBusinessName: string
  newBusinessType: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newCreateBuyOrderParams = (): CreateBuyOrderParams => {
  return {
    buyPrice: '',
    landId: 0,
    expiredInNDays: 0,
    newBusinessName: '',
    newBusinessType: 0,
    success: null,
    err: null,
  }
}

export interface AcceptBuyOrderParams {
  sellPrice: string
  landId: number
  buyOrderAutonumber: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newAcceptBuyOrderParams = (): AcceptBuyOrderParams => {
  return {
    sellPrice: '',
    landId: 0,
    buyOrderAutonumber: 0,
    success: null,
    err: null,
  }
}

export interface AcceptItemBuyOrderParams {
  sellPrice: string
  itemId: number
  buyOrderAutonumber: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newAcceptItemBuyOrderParams = (): AcceptItemBuyOrderParams => {
  return {
    sellPrice: '',
    itemId: 0,
    buyOrderAutonumber: 0,
    success: null,
    err: null,
  }
}

export interface CreateSellOrderParams {
  sellPrice: string
  landId: number
  expiredInNDays: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newCreateSellOrderParams = (): CreateSellOrderParams => {
  return {
    sellPrice: '',
    landId: 0,
    expiredInNDays: 0,
    success: null,
    err: null,
  }
}

export interface SetupBusinessParams {
  fee: string
  landId: number
  businessName: string
  businessType: number
  buildingItemId: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newSetupBusinessParams = (): SetupBusinessParams => {
  return {
    fee: '',
    landId: 0,
    businessName: '',
    businessType: 0,
    buildingItemId: '',
    success: null,
    err: null,
  }
}

export interface RegisterCitizenParams {
  fee: string
  name: string
  occupation: number
  avatarItemId: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newRegisterCitizenParams = (): RegisterCitizenParams => {
  return {
    fee: '',
    name: '',
    occupation: 0,
    avatarItemId: '',
    success: null,
    err: null,
  }
}


export interface BuyNativeTokenWithCityTokenParams {
  tokenName: string
  amount: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newBuyNativeTokenWithCityTokenParams = (): BuyNativeTokenWithCityTokenParams => {
  return {
    tokenName: '',
    amount: '',
    success: null,
    err: null,
  }
}

export interface BuyCityTokenWithNativeTokenParams {
  tokenName: string
  amount: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newBuyCityTokenWithNativeTokenParams = (): BuyCityTokenWithNativeTokenParams => {
  return {
    tokenName: '',
    amount: '',
    success: null,
    err: null,
  }
}

export interface ApproveERC20TokenParams {
  tokenName: string
  approveContractAddr: string
  amount: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newApproveERC20TokenParams = (): ApproveERC20TokenParams => {
  return {
    tokenName: '',
    approveContractAddr: '',
    amount: 10000000,
    success: null,
    err: null,
  }
}
export interface AllowanceOfERC20TokenParams {
  tokenName: string
  contractAddr: string
}

export const newAllowanceOfERC20TokenParams = (): AllowanceOfERC20TokenParams => {
  return {
    tokenName: '',
    contractAddr: zeroAddr,
  }
}

export interface BuyMetaLandWithCityTokenParams {
  landId: string
  businessName: string
  businessType: number
  amountIn: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newBuyMetaLandWithCityTokenParams = (): BuyMetaLandWithCityTokenParams => {
  return {
    landId: '',
    businessName: '',
    businessType: 0,
    amountIn: '',
    success: null,
    err: null,
  }
}

export interface MintFaucetsParams {
  amountOut: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newMintFaucetsParams = (): MintFaucetsParams => {
  return {
    amountOut: '',
    success: null,
    err: null,
  }
}

export interface PostItemForSellParams {
  landId: string
  itemName: string
  itemType: number
  contentUrl: string
  itemPrice: string
  priceIncrRate: number
  priceIncrNItems: number
  totalItems: number
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newPostItemForSellParams = (): PostItemForSellParams => {
  return {
    landId: '',
    itemName: '',
    itemType: 0,
    contentUrl: '',
    itemPrice: '',
    priceIncrRate: 0,
    priceIncrNItems: 0,
    totalItems: 0,
    success: null,
    err: null,
  }
}

export interface ListItemParams {
  landId: string
  fromAutonumber: number
}

export const newListItemParams = (): ListItemParams => {
  return {
    landId: '',
    fromAutonumber: 0,
  }
}

export interface BuyItemParams {
  landId: string
  autonumber: number
  amountIn: string
  success: (IStatus) => void
  err: (IStatus) => void
}

export const newBuyItemParams = (): BuyItemParams => {
  return {
    landId: '',
    autonumber: 0,
    amountIn: '',
    success: null,
    err: null,
  }
}

export interface CurrentLandPriceParams {
  landId: string
}

export const newCurrentLandPriceParams = (): CurrentLandPriceParams => {
  return {
    landId: '',
  }
}