























import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import { fromWei, getChainId, toWei } from '~/utils/util'
import {
  FormMode,
  ItemType,
  PopupMode,
} from './models'
import { APILoader } from '~/loader/APILoader'
import { 
  getAPIEndpoint,
  getPathWithBaseURL,
} from '~/consts/config'
import { APIResponseError } from '~/consts/alerts'

@Component({
  components: {
  },
})
export default class SellItemForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    this.beginTransaction()
    this.postItemForSellParams.landId = value.landId
    this.postItemForSellParams.itemName = value.itemName
    this.postItemForSellParams.itemType = Number(value.itemType)
    this.postItemForSellParams.contentUrl = value.contentUrl
    this.postItemForSellParams.itemPrice = value.itemPrice
    this.postItemForSellParams.priceIncrRate = Number(value.incrementalRate)
    this.postItemForSellParams.priceIncrNItems = 1
    this.postItemForSellParams.totalItems = Number(value.totalItems)
    this.postItemForSellParams.success = this.endTransaction
    this.postItemForSellParams.err = () => { this.endContractTransactionError(this.postItemForSell) }
    this.postItemForSell.call()
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransaction() {
    this.isLoading = false
    this.onCloseFormClick()
    this.popupMode = PopupMode.ItemList
  }

  uploadedFile: any
  get uploadNFTImage(): APILoader<any> {

    const formData = new FormData();
    formData.append('file', this.uploadedFile)

    return new APILoader(this, (data: any) => ({
      stateKey: 'upload_nft_image',
      baseURL: getAPIEndpoint(`/api/nft/upload`),
      formData: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onCallSuccess: () => {
        if (this.uploadNFTImage.callItems.length > 0) {
          this.formRepo.updateAttr(this.form_name, 'contentUrl', this.uploadNFTImage.callItems[0].file_name)
        }
      },
      onCallError: (resErr: any) => {
        this.alert(APIResponseError(resErr))
      }
    }))
  }

  get allItemTypes(): IOption[] {
    return [
      {
        value: ItemType.Avatar,
        label: 'Avatar'
      },
      {
        value: ItemType.Building,
        label: 'Building'
      },
      {
        value: ItemType.BrandedProduct,
        label: 'Virtual Branded Product'
      },
    ]
  }

  get sellItemFormOptions(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'landId',
          label: 'Land Id',
          rules: [RuleHelper.required],
          disabled: true,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'itemName',
          label: 'Item name',
          rules: [RuleHelper.required]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'itemType',
          label: 'Item type',
          placeholder: '',
          defaultValue: ItemType.BrandedProduct,
          className: 'select-item-type',
          rules: [RuleHelper.required],
          options: this.allItemTypes,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'itemPrice',
          label: 'Initial Price',
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'incrementalRate',
          label: 'Price increment rate for each item sold',
          placeholder: '',
          defaultValue: '1',
          className: 'incremental-rate',
          rules: [RuleHelper.required],
          options: [
            {
              value: '0',
              label: 'No increment'
            },
            {
              value: '1',
              label: '1%'
            },
            {
              value: '2',
              label: '2%'
            },
            {
              value: '3',
              label: '3%'
            },
            {
              value: '4',
              label: '4%'
            },
            {
              value: '5',
              label: '5%'
            },
            {
              value: '6',
              label: '6%'
            },
            {
              value: '7',
              label: '7%'
            },
            {
              value: '8',
              label: '8%'
            },
            {
              value: '9',
              label: '9%'
            },
            {
              value: '10',
              label: '10%'
            }
          ]
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'totalItems',
          label: 'Total items',
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.FILE,
        col: '12',
        props: {
          name: 'nftUpload',
          label: 'NFT Content',
          accept: 'image/png',
          rules: [RuleHelper.required],
          on: {
            filechanged: (file: any) => {
              // 1. reset contentUrl if file has changed
              this.formRepo.updateAttr(this.form_name, 'contentUrl', '')
              if (!file) {
                return
              }

              // 2. upload new file
              this.uploadedFile = file
              this.uploadNFTImage.call()
            }
          }
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'contentUrl',
          label: 'Content URL',
          disabled: true,
          rules: [RuleHelper.required, RuleHelper.url],
        }
      },
    ]
  }
}

interface FormData {
  landId: string
  itemName: string
  itemType: string
  itemPrice: string
  incrementalRate: string
  totalItems: string
  contentUrl: string
}

