import { count } from 'console'
import { StringHelper } from '../utils/StringHelper'

export interface PurchaseHistory {
  price: string
	buyer_addr: string
	seller_addr: string
	block_number: string
}

export interface LandLocation {
  land_id: string
	country_id: string
	country_name: string
	country_alias: string
	city_id: string
	city_name: string
	city_alias: string
	block: number
	cell: number
}

export enum PopOverItemType {
  None = 0,
  Business,
  SellItem,
  MyItem,
  MyItemWithBuyOrder,
  Avatar,
  ItemOutOfStock,
}
export interface PopOverItem {
  item_id: string
  item_type: PopOverItemType
  item_name: string
  item_content_url: string
  explain: string
  building_type: number
}

export const newPopOverItem = (): PopOverItem => {
  return {
    item_id: '',
    item_type: PopOverItemType.None,
    item_name: '',
    item_content_url: '',
    explain: '',
    building_type: 0,
  }
}

export interface MapExtra {
  id: string
  extra_type: string
  extra_count: number
}

export interface MapCell {
  id: string
  extras: MapExtra
}

// Don't modify the ordinal number, it referenced in smartcontract
export enum ItemType {
  Building = 1,
  Avatar = 2,
  BrandedProduct = 3,
  Ticket = 4,
  Coupon = 5,
}

// Don't modify the ordinal number, it referenced in smartcontract
export enum Occupation {
  Programmer = 1,
  Designer = 2,
  Marketer = 3,
  Creative = 4,
  Business = 5,
  PropertyDeveloper = 6,
}

// Don't modify the ordinal number, it referenced in smartcontract
export enum BusinessType {
  ResidenceAndApartment = 1,
  InteriorDesign = 2,
  BrandedConsumerProducts = 3,
  ArtAndPhotoGallery = 4,
  BookAndContentPublisher = 5,
  ClipAndMusicProduction = 6,
  MallAndOutlet = 7,
}

export enum PopOverMode {
  None = 0,
  ItemDetail = 1,
  Banner,
}

export enum PopupMode {
  None = 0,
  ItemList = 1,
}

export enum ListMode {
  None = 0,
  BusinessDetail = 1,
  MyAssetList,
  TweetList,
}

export enum FormMode {
  SelectCityForm = 1,
  BuyLandForm,
  SellItemForm,
  BuyItemForm,
  BidItemForm,
  AcceptItemBuyOrderForm,
  GetCityTokenForm,
  GetNativeTokenForm,
  RegisterCitizenForm,
  SetupBusinessForm,
  SellBusinessForm,
  AcceptBuyOrderForm,
  BuyBusinessForm,
  TweetForm,
  OfferBuyBusinessForm,
}

export interface CityGrid {
  rows: number
  cols: number
  cells: CityCell[]
}

export const newCityGrid = (): CityGrid => {
  const grid: CityGrid = {
    rows: 15,
    cols: 15,
    cells: [],
  }

  // {building_type: 'bld1', building_size: 'l', is_business: true},
  for (let i=0; i<grid.rows * grid.cols; i++) {
    grid.cells.push({
      id: StringHelper.genString(10),
      owner_addr: '',
      building_type: '',
      building_size: '',
      is_business: true,
      business_name: '',
      business_type: 0,
      business_type_name: '',
      building_content_url: '',
      seller_addr: '',
      sell_price: '',
      sell_ended_block: '',
      sell_order_autonumber: 0,
      buyer_addr: '',
      buy_price: '',
      buy_ended_block: '',
      buy_order_autonumber: 0,
    })
  }
  return grid
}

export interface CityCell {
  id: string
  owner_addr: string
  building_type: string
  building_size: string // g (ground), l (long building), m (medium building)
  is_business: boolean
  business_name: string
  business_type: number
  business_type_name: string
  building_content_url: string
  seller_addr: string
	sell_price: string
	sell_ended_block: string
	sell_order_autonumber: number
  buyer_addr: string
  buy_price: string
  buy_ended_block: string
  buy_order_autonumber: number
}

export const newCityCell = (): CityCell => {
  return {
    id: '',
    owner_addr: '',
    building_type: '',
    building_size: '',
    is_business: false,
    business_name: '',
    business_type: 0,
    business_type_name: '',
    building_content_url: '',
    seller_addr: '',
    sell_price: '',
    sell_ended_block: '',
    sell_order_autonumber: 0,
    buyer_addr: '',
    buy_price: '',
    buy_ended_block: '',
    buy_order_autonumber: 0,
  }
}

export interface CityGridParam {
  country: string
  city: string
  block: number
}

export const newCityGridParam = (
  country: string = 'thailand', 
  city: string = 'bangkok', 
  block: number = 0): CityGridParam => {
    
  return {
    country: country,
    city: city,
    block: block,
  }
}
