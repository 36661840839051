
















import { Component, Model, Prop, Watch } from 'vue-property-decorator'
import { DefaultAvatarURL, getPathWithBaseURL } from '~/consts/config';
import { BaseApp } from '~/core/BaseApp'
import { isAllowImageUrl } from '~/utils/util';
import { CityBaseForm } from './CityBaseForm';

@Component
export default class Banner extends CityBaseForm<any> {
  closeBanner() {
    this.closePopOver()
  }
}
