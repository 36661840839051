











































































import { Component, Provide, Watch } from 'vue-property-decorator'
import { IOption } from '~/components/form/FormTypes'
import { BaseApp } from '~/core/BaseApp'
import { fromWei, toRounded } from '~/utils/util'
import { CityBaseForm } from './CityBaseForm'
import { FormMode } from './models'

@Component({
  components: {
  },
})
export default class Menu extends CityBaseForm<any> {

  @Provide('form_name') form_name = 'city_form'

  get yourCityToken(): string {
    const balCityTokenLoader = this.balanceOfCityToken
    if (balCityTokenLoader.callItems.length > 0) {
      const bal = fromWei(balCityTokenLoader.callItems[0])
      return toRounded(bal)
    }
    return '0'
  }

  get getSelectedCountry(): string {
    if (this.cityGridParam.country == null || this.cityGridParam.country.length == 0) {
      return ''
    }

    return `(${this.cityGridParam.country})`
  }

  onLoginMetamask() {
    this.loginMetamask()
  }

  onGetCityTokenClick() {
    this.formMode = FormMode.GetCityTokenForm
  }

  onSellCityTokenClick() {
    this.formMode = FormMode.GetNativeTokenForm
  }

  onCountryMenuClick(country: IOption) {
    const capitalCity = this.capitalByCountry(country.value)
    this.$router.push({ path: `/${country.value}/${capitalCity}/0/` })
  }
}
