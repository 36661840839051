




































import { Component, Provide, Watch, Prop } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { 
  FormMode, 
  ListMode,
  PopOverMode,
  PopOverItemType,
} from './models'
import { MarketItem, NFTItem } from '~/types/meta_city'
import { fromWei, toWei } from '~/utils/util'

@Component({
  components: {
  },
})
export default class MyAssetList extends CityBaseForm<any> {
  @Provide('form_name') form_name = 'city_form'

  get myItems(): NFTItem[] {
    return this.myItemsLoader.callItems
  }

  hasBuyOrder(item: NFTItem): boolean {
    return item.buy_order_autonumber > 0
  }

  calBuyOrderPrice(item: NFTItem): string {
    return fromWei(item.buy_price)
  }

  calNFTItemImageSrc(item: NFTItem): string {
    return item.item_content_url
  }

  onMyNFTClick(item: NFTItem) {
    const popOverItem = {
      item_id: `${item.creator_business_id}`,
      item_type: PopOverItemType.MyItem,
      item_name: item.item_name,
      item_content_url: item.item_content_url,
      explain: '',
      building_type: 0,
    }
    const hasBuyOrder = this.hasBuyOrder(item)
    const buyPrice = this.calBuyOrderPrice(item)
    if (hasBuyOrder) {
      popOverItem.explain = `Wallet address ${item.buyer_addr} offer to buy your NFT for ${buyPrice} BUSD, click 'Accept Bidding' to sell your NFT`
      popOverItem.item_type = PopOverItemType.MyItemWithBuyOrder
    }

    this.selectedMyNFT = item
    
    this.popOverItem = popOverItem
    this.landLocationLoader.call()
    this.popOverMode = PopOverMode.ItemDetail
  }
}
