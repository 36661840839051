

































import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { fromWei, getChainId, toWei, toBN } from '~/utils/util'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import { 
    getAddressOfContract, 
    ContractNames
} from '~/consts/config'
import { ListMode, PopupMode } from './models'

@Component({
  components: {
  },
})
export default class BuyItemForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  isAllowanceApproved: boolean = true

  checkAllowance: () => void

  created() {
    this.checkAllowance = _debounce(() => {
      this._onCheckAllowance()
    }, 300)
  }

  async onCheckAllowance() {
    const form = this.form
    if (!form.buyAmountIn || form.buyAmountIn.length == 0) {
      this.isAllowanceApproved = true
      return
    }

    this.allowanceOfERC20TokenParams.tokenName = 'BUSD'
    this.allowanceOfERC20TokenParams.contractAddr = getAddressOfContract(ContractNames.MetaMarket)
    const getAllowance = this.allowanceOfERC20Token
    await getAllowance.call()

    const res = `${getAllowance.callItems[0]}`
    const allowance = toBN(res)
    this.isAllowanceApproved = allowance.gte(toWei(form.buyAmountIn))
  }

  async _onCheckAllowance() {
    this.onCheckAllowance()
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: any, oldVal: any) {
    this.checkAllowance()
  }

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    if (!this.isAllowanceApproved) {
      this.beginTransaction()
      this.approveERC20TokenParams.tokenName = 'BUSD'
      this.approveERC20TokenParams.approveContractAddr = getAddressOfContract(ContractNames.MetaMarket)
      this.approveERC20TokenParams.success = this.endTransactionApprove
      this.approveERC20TokenParams.err = () => { this.endContractTransactionError(this.approveERC20Token) }
      this.approveERC20Token.call()
    } else {
      this.onBuyItem(value)
    }
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransactionApprove() {
    this.isLoading = false
    this.checkAllowance()
    this.onBuyItem(this.form)
  }

  endTransactionBuy() {

    this.isLoading = false

    if (this.buyItem.callItems.length > 0) {
      const blockNumber = this.buyItem.callItems[0].blockNumber

      const reloadMyItems = async () => {
        this.myItemsRefreshParams.blockNumber = blockNumber
        await this.myItemsRefresh.call()
        this.myItemsLoader.call()
      }
      reloadMyItems()
    }
    
    this.balanceOfCityToken.call()
    
    this.onCloseFormClick()
    this.popupMode = PopupMode.None
    this.listMode = ListMode.MyAssetList
  }

  onBuyItem(value: FormData) {
    this.beginTransaction()
    this.buyItemParams.landId = value.landId
    this.buyItemParams.autonumber = value.buyItemAutonumber
    this.buyItemParams.amountIn = value.buyAmountIn
    this.buyItemParams.success = this.endTransactionBuy
    this.buyItemParams.err = () => { this.endContractTransactionError(this.buyItem) }
    this.buyItem.call()
  }

  get selectedNFTUrl(): string {
    return this.formRepo.getAttr(this.form_name, 'buyItemContentUrl')
  }

  get buyItemFormOptions(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'landId',
          label: 'Buy From Business Id',
          rules: [RuleHelper.required],
          disabled: true,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'buyItemAutonumber',
          label: 'Item Id',
          rules: [RuleHelper.required],
          disabled: true,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '6',
        props: {
          name: 'buyAmountIn',
          label: 'Buy Price',
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '6',
        props: {
          name: 'buyWithToken',
          placeholder: '',
          defaultValue: 'BUSD',
          className: 'select-token',
          disabled: true,
          rules: [RuleHelper.required],
          options: [
            {
              value: 'BUSD',
              label: 'BUSD'
            }
          ]
        }
      },
    ]
  }
}

interface FormData {
  landId: string
  buyItemAutonumber: number
  buyAmountIn: string
}
