export interface SellOrder {
  land_id: string
  autonumber: number
  price: string
  ended_block: number
  seller_addr: string
  completed: boolean
  deleted: boolean
  created_at: number
} 

export interface BuyOrder {
  land_id: string
  autonumber: number
  price: string
  ended_block: number
  buyer_addr: string
  business_name: string
  business_type: number
  completed: boolean
  deleted: boolean
  overrided: boolean
  created_at: number
} 

export interface CitizenInfo {
  name: string
  occupation: number
  avatar_item_id: number
  avatar_content_url: string
}

export const newCitizenInfo = (): CitizenInfo => {
  return {
    name: '',
    occupation: 0,
    avatar_item_id: 0,
    avatar_content_url: '',
  }
}

export interface TweetItem {
  autonumber: number
  message: string
  tweet_from: string
  tweet_to: string
  item_id: string
  land_id: string
  citizen_name: string
  avatar_content_url: string
  business_name: string
  business_type: number
  building_content_url: string
  item_name: string
  item_content_url: string
  sell_id: string
  sell_item_name: string
  sell_content_url: string
  created_at_block: string
}

export interface MarketItem {
  land_id: string
  autonumber: number
  item_content_url: string
  item_name: string
  item_type: number
  item_type_name: string
  initial_price: string
  current_price: string
  price_incremental_rate: number
  price_increment_every_n_items: number
  total_items: number
  last_increment_count: number
  sold_items: number
  is_paused: boolean
}

export interface NFTItem {
  item_id: string
  item_name: string
  item_type: number
  item_type_name: string
  item_content_url: string
  creator_business_id: string
  creator_business_name: string
  buyer_addr: string
  buy_price: string
  buy_ended_block: string
  buy_order_autonumber: number
}

export const newNFTItem = (): NFTItem => {
  return {
    item_id: '',
    item_name: '',
    item_type: 0,
    item_type_name: '',
    item_content_url: '',
    creator_business_id: '',
    creator_business_name: '',
    buyer_addr: '',
    buy_price: '',
    buy_ended_block: '',
    buy_order_autonumber: 0
  }
}

export interface LandInfo {
  owner_addr: string
  business_name: string
  business_type: number
  building_item_id: number
  building_content_url: string
  sell_order_autonumber: number
  sell_price: string
  sell_ended_block: number
  seller_addr: string
  buy_order_autonumber: number
  buy_price: string
  buy_ended_block: number
  buyer_addr: string
  buyer_new_business_name: string
  buyer_new_business_type: number
  current_price: string
}