import moment from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

moment.extend(relativeTime)
moment.extend(utc)

export const DATETIMEFORMAT = 'YYYY-MM-DD HH:mm'
export const DATEFORMAT = 'YYYY-MM-DD'
export const TIMEFORMAT = 'HH:mm:ss'
const isTimeInValid = (time: any) => time === 'Invalid Date'

export class TimeHelper {
  static toUTC = (time: string): string => {
    if (!time) {
      return ''
    }
    try {
      const newTime = moment(time)
        .subtract(7, 'hour')
        .format('YYYY-MM-DD HH:mm:ss')
      return isTimeInValid(newTime) ? time : newTime
    } catch (e) {
      return time.toString()
    }
  }

  static toLocal = (time: string, format: string = 'YYYY-MM-DD HH:mm:ss'): string => {
    if (!time) {
      return ''
    }
    try {
      const newTime = moment(time).add(7, 'hour').format(format)
      return isTimeInValid(newTime) ? time : newTime
    } catch (e) {
      return time
    }
  }

  static addSecondToDateTime = (time: string, seconds: number, format: string = 'YYYY-MM-DD HH:mm:ss'): string => {
    if (!time) {
      return ''
    }
    try {
      const newTime = moment(time).add(seconds, 'seconds').format(format)
      return isTimeInValid(newTime) ? time : newTime
    } catch (e) {
      return time
    }
  }

  static getDay = (time: string): number => {
    if (!time) {
      return 1
    }
    try {
      return +moment(time, 'YYYY-MM-DD HH:mm:ss').format('D')
    } catch (e) {
      return 1
    }
  }

  static getMonth = (time: string): number => {
    if (!time) {
      return 1
    }
    try {
      return +moment(time, 'YYYY-MM-DD HH:mm:ss').format('M')
    } catch (e) {
      return 1
    }
  }

  static getYear = (time: string): number => {
    if (!time) {
      return 1
    }
    try {
      return +moment(time, 'YYYY-MM-DD HH:mm:ss').format('YYYY')
    } catch (e) {
      return 2563
    }
  }

  static getShortTimeForTable = (time: string): string => {
    if (!time) {
      return ''
    }
    try {
      const newTime = moment(time).fromNow()
      return isTimeInValid(newTime) ? time : newTime
    } catch (e) {
      return time
    }
  }

  static getFullTextFormatDate = (time: string): string => {
    if (!time) {
      return ''
    }
    try {
      const newTime = moment(time).format('MMMM D, YYYY')
      return isTimeInValid(newTime) ? time : newTime
    } catch (e) {
      return time
    }
  }

  static getCurrentDate = (): string => {
    const newTime = moment().format('YYYY-MM-DD')
    return isTimeInValid(newTime) ? '' : newTime
  }

  static getDateFromTime = (time: string, format: string = 'DD/MM/YYYY'): string => {
    if (!time) {
      return ''
    }
    const newTime = moment(time).format(format)
    return isTimeInValid(newTime) ? time : newTime
  }

  static getDateFormTimeWithLocal = (time: string, format: string = 'DD/MM/YYYY'): string => {
    if (!time) {
      return ''
    }
    const newTime = moment(TimeHelper.toLocal(time)).format(format)
    return isTimeInValid(newTime) ? time : newTime
  }

  static getISODateTimeFormTime = (time: string): string => {
    if (!time) {
      return ''
    }

    const testTime = moment(time).format('YYYY-MM-DD HH:mm:ss')
    if (isTimeInValid(testTime)) {
      return time
    }

    const newTime = moment(time).toISOString()
    return isTimeInValid(newTime) || !newTime ? time : newTime
  }

  static getDateTimeFromDateTime = (time: string): string => {
    if (!time) {
      return ''
    }
    const newTime = moment(time).format('YYYY-MM-DD HH:mm:ss')
    return isTimeInValid(newTime) ? time : newTime
  }

  static getDateTimeFromTime = (time: string): string => {
    if (!time) {
      return ''
    }
    const dateCurrent = moment().format(DATEFORMAT)
    const newTime = moment(`${dateCurrent} ${time}`).format('YYYY-MM-DD HH:mm:ss')
    return isTimeInValid(newTime) ? time : newTime
  }

  static getTimeFromTime = (time: string, format: string = 'HH:mm'): string => {
    if (!time) {
      return ''
    }
    const newTime = moment(time).format(format)
    return isTimeInValid(newTime) ? time : newTime
  }

  static isDateTimeValid = (time: string): boolean => {
    if (!time) {
      return false
    }
    return /^(\d{4})\-(\d{2})\-(\d{2}) (\d{2}):(\d{2})(:\d{2})?$/.test(time)
  }

  static getCurrentDateTime = (): string => {
    const newTime = moment().format('YYYY-MM-DD HH:mm:ss')
    return isTimeInValid(newTime) ? '' : newTime
  }

  static startDateFillTime = (date): string => {
    if (!date) {
      return date
    }
    const newTime = moment(date).format(DATEFORMAT)
    return isTimeInValid(newTime) ? date : `${newTime} 17:00:00`
  }

  static endDateFillTime = (date): string => {
    if (!date) {
      return date
    }
    const newTime = moment(date).format(DATEFORMAT)
    return isTimeInValid(newTime) ? date : `${newTime} 16:59:59`
  }

  static isBefore = (date: string, date2: string): boolean =>
    moment(date).isBefore(date2, 'minute')
   
  static isAfter = (date: string, date2: string): boolean =>
    moment(date).isAfter(date2, 'minute')
    
  static getTimeDiffInHour = (date: string, date2: string): number => 
    moment(date).diff(moment(date2), 'hour')
}
