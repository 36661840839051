




















import { Component, Model, Prop, Watch } from 'vue-property-decorator'
import { BaseApp } from '~/core/BaseApp'
import { CityBaseForm } from './CityBaseForm';

@Component
export default class LoginDialog extends CityBaseForm<any> {

  @Prop(Boolean) readonly value!: boolean;

  isShowDialog = false

  @Watch("isShowDialog")
  public onDialogChanged(val: boolean) {
    this.$emit("input", val);
  }

  @Watch("value")
  public onValueChanged(val: boolean) {
    this.isShowDialog = val;
  }

  onLoginMetamask() {
    this.loginMetamask()
    this.isShowDialog = false
  }
}
