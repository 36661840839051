




















































import { Component, Provide, Watch } from 'vue-property-decorator'
import { 
  CitizenInfo,
  newCitizenInfo, 
  TweetItem,
} from '~/types/meta_city'
import { CityBaseForm } from './CityBaseForm'
import { 
  FormMode,
  ListMode,
  PopOverMode,
  PopOverItemType,
} from './models'
import { MetaCityHelper } from '~/utils/MetaCityHelper'
import { 
  DefaultAvatarURL,
  DefaultAvatarName
} from '~/consts/config'
import { 
  fromWei, 
  toBN, 
  toRounded,
  isAllowImageUrl
} from '~/utils/util'
import BN from 'bn.js'

@Component({
  components: {
  },
})
export default class TweetList extends CityBaseForm<any> {
  @Provide('form_name') form_name = 'city_form'

  get tweets(): TweetItem[] {
    // TweetItem {
    //   autonumber: number
    //   message: string
    //   tweet_from: string
    //   tweet_to: string
    //   item_id: string
    //   land_id: string
    //   citizen_name: string
    //   avatar_content_url: string
    //   business_name: string
    //   building_content_url: string
    //   item_name: string
    //   item_content_url: string
    //   sell_id: string
    //   sell_item_name: string
    //   sell_content_url: string
    //   created_at_block: string
    // }
    return this.listTweets.callItems
  }

  get numberOfTweets(): string {
    if (this.listTweetsLastAutonumber.callItems.length == 0){
      return '0'
    }
    return this.listTweetsLastAutonumber.callItems[0]
  }

  onPrevPageClick() {
    let fromAutonumber = 0
    if (this.listTweets.callItems.length > 0) {
      const len = this.listTweets.callItems.length
      fromAutonumber = this.listTweets.callItems[0].autonumber
      fromAutonumber += 10
      if (fromAutonumber > Number(this.numberOfTweets)) {
        // continue from first page
        fromAutonumber = 0
      }
    }

    this.listTweetsParams.fromAutonumber = fromAutonumber
    this.listTweets.call()
  }

  onNextPageClick() {
    let fromAutonumber = 0
    if (this.listTweets.callItems.length > 0) {
      const len = this.listTweets.callItems.length
      fromAutonumber = this.listTweets.callItems[len-1].autonumber
      fromAutonumber -= 1
      if (fromAutonumber <= 0) {
        // continue from first page
        fromAutonumber = 0
      }
    }

    this.listTweetsParams.fromAutonumber = fromAutonumber
    this.listTweets.call()
  }

  onMyBusinessClick(tweet: TweetItem) {
    this.popOverItem = {
      item_id: tweet.land_id,
      item_type: PopOverItemType.Business,
      item_name: tweet.business_name,
      explain: '',
      item_content_url: tweet.building_content_url,
      building_type: tweet.business_type,
    }
    this.landLocationLoader.call()
    this.popOverMode = PopOverMode.ItemDetail
  }

  onMyNFTClick(tweet: TweetItem) {
    this.popOverItem = {
      item_id: tweet.item_id,
      item_type: PopOverItemType.MyItem,
      item_name: tweet.item_name,
      explain: '',
      item_content_url: tweet.item_content_url,
      building_type: 0,
    }
    this.landLocationLoader.call()
    this.popOverMode = PopOverMode.ItemDetail
  }

  onSellItemClick(tweet: TweetItem) {
    this.popOverItem = {
      item_id: tweet.sell_id,
      item_type: PopOverItemType.SellItem,
      item_name: tweet.sell_item_name,
      explain: '',
      item_content_url: tweet.sell_content_url,
      building_type: 0,
    }
    this.landLocationLoader.call()
    this.popOverMode = PopOverMode.ItemDetail
  }

  onTweetItemClick(tweet: TweetItem) {

  }

  onCloseTweetListClick() {
    this.onClosePopupClick()
    this.onCloseListClick()
  }

  calTweetUsername(tweet: TweetItem): string {
    if (tweet.citizen_name.length > 0) {
      return tweet.citizen_name
    }
    return DefaultAvatarName
  }

  calTweetImage(tweet: TweetItem): string {
    if (isAllowImageUrl(tweet.avatar_content_url)) {
      return tweet.avatar_content_url
    }
    return DefaultAvatarURL
  }

  calTweetItemClass(item: TweetItem): {[key: string]: boolean} {
    const classes = {
      'tweets-row': true,
    }
    return classes
  }
}
