import { _get } from '~/utils/lodash'
import { commonListState } from '~/types/states'
import { ReplaceAppState } from '~/store/app'
import { WaitGroup } from '~/utils/WaitGroup'
import { 
  BaseLoader, 
  ILoaderOption, 
  IParam,
} from './BaseLoader'
import { 
  NewRequester,
  IResponse,
} from '~/core/Requester'
import { TimeHelper } from '~/utils/TimeHelper'

export interface ICallOption {
  params?: IParam
  options?: object
}

declare class IParamData {
  public params: object
}

export declare class ICallData extends IParamData {
  public q: string
  public options: ICallOption
}

export class CallData implements ICallData {
  public params: object = {}
  public opts: object = {}

  constructor(
    public q: string = '', 
    public options: ICallOption = {}) {

    this.params = {
      q,
      ...options.params
    }
    this.opts = {
      ...options.options,
    }
  }
}

export class APILoader<T = any> extends BaseLoader<T> {
  constructor(
    protected _vm: any,
    public options: (data?: any) => ILoaderOption<T>,
  ) {

    super(_vm, options)

    const stateKey = this.callStateKey
    const state = this.getStateByKey(stateKey)
    if (!state) {
      this.commit(new ReplaceAppState(stateKey, commonListState()))
    }
  }

  public reset = () => {
    const stateKey = this.callStateKey
    this.commit(new ReplaceAppState(stateKey, commonListState()))
  }

  public call = async (wg: WaitGroup = null) => {

    const stateKey = this.callStateKey
    const opts = this.options()
    
    try {
      this.toLoadingStatus(stateKey)
      
      const reqOpts = {
        headers: {
          'x-timestamp': TimeHelper.toUTC(TimeHelper.getCurrentDateTime()),
          ...opts.headers,
        },
        params: {
          ...opts.params,
        }
      }

      let resp: IResponse<any>
      if (opts.formData != null) {
        // POST request
        resp = await NewRequester.post<any>(opts.baseURL, opts.formData, reqOpts)
      } else {
        // GET request
        resp = await NewRequester.get<any>(opts.baseURL, reqOpts)
      }

      let pageOpts = this.toPageOption(resp)

      const respData = resp.data?.items || resp.data || []
      if (Array.isArray(respData)) {
        this.toSuccessItemsStatus(stateKey, respData, pageOpts)
      } else {
        this.toSuccessItemsStatus(stateKey, [respData], pageOpts)
      }
      
      if (opts.onCallSuccess) {
        opts.onCallSuccess(this.callStatus)
      }
    } catch (e) {
      this.toErrorStatus(stateKey, e.resp?.data || e)
      if(opts.onCallError){
        opts.onCallError(this.callStatus)
      }
    }finally {
      this.toCompleteStatus(stateKey)
      if (wg) {
        wg.done()
      }
    }
  }
}
