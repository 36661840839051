import { getWeb3, toBytes32 } from './util'
import ganache from 'ganache-cli'
import Web3 from 'web3';
import internal from 'stream';

export const gasLimit = 6721975

export const nativeTokens = ['BUSD', 'USDT', 'USDC', 'DAI']

export interface TestActors {
  ownerAddr: string
  ownerTx: any
  acc1Addr: string
  acc1Tx: any
  acc2Addr: string
  acc2Tx: any
  acc3Addr: string
  acc3Tx: any
}

export interface TestLands {
  land1: string
  land1Cell2: string
  land2: string
  land3: string
  land4: string
}

export interface TestBizs {
  biz1Name: string
  biz1Type: number
  biz2Name: string
  biz2Type: number
  biz3Name: string
  biz3Type: number
  biz4Name: string
  biz4Type: number
}

export interface TestCitizens {
  name1: string
  occupation1: number
  name2: string
  occupation2: number
  name3: string
  occupation3: number
  name4: string
  occupation4: number
}

export const testBizs = (): TestBizs => {
  return {
    biz1Name: 'my business #1',
    biz1Type: 1,
    biz2Name: 'my business #2',
    biz2Type: 2,
    biz3Name: 'my business #3',
    biz3Type: 3,
    biz4Name: 'my business #4',
    biz4Type: 4,
  }
}

// format of landId
// country 100-999
// city 000-999
// block 000-999
// land 000-999
// some of valid landId are 100001000020, 301002007018
export const testLands = (): TestLands => {
  return {
    land1: '100000000000',
    land1Cell2: '100000000002',
    land2: '100001001002',
    land3: '999999099099',
    land4: '101009009009',
  }
}

export const nextNBlockNumber = async (web3: Web3, n: number): Promise<number> => {
  let block = await web3.eth.getBlockNumber()
  return Number(block + n)
}

export const testActors = async (web3: Web3): Promise<TestActors> => {
  const accounts = await web3.eth.getAccounts()
  return {
    ownerAddr: accounts[0],
    ownerTx: {from: accounts[0], gas: gasLimit},
    acc1Addr: accounts[1],
    acc1Tx: {from: accounts[1], gas: gasLimit},
    acc2Addr: accounts[2],
    acc2Tx: {from: accounts[2], gas: gasLimit},
    acc3Addr: accounts[3],
    acc3Tx: {from: accounts[3], gas: gasLimit},
  }
}

export interface TestItems {
  item1Name: string
  item1ContentUrl: string
  item1Type: number
  item2Name: string
  item2ContentUrl: string
  item2Type: number
}

export const testItems = (): TestItems => {
  return {
    item1Name: 'item 1 name',
    item1ContentUrl: 'https://contents/1',
    item1Type: 1,
    item2Name: 'item 2 name',
    item2ContentUrl: 'https://contents/2',
    item2Type: 2,
  }
}

export const testCitz = (): TestCitizens => {
  return {
    name1: 'citz name 1',
    occupation1: 1,
    name2: 'citz name 2',
    occupation2: 2,
    name3: 'citz name 3',
    occupation3: 3,
    name4: 'citz name 4',
    occupation4: 4,
  }
}