import Web3 from 'web3'
import BN from 'bn.js'
import { round } from 'lodash'

export const getNetwork = (): string => {
  return process.env.NETWORK
}

export const getChainId = (): string => {
  const network = getNetwork()
  switch (network) {
    case 'bsctest': return '0x61'
    case 'bscmain': return '0x38'
  }
  return ''
}

export const getWeb3 = (provider: any): Web3 => {
  const web3 = new Web3(provider)
  return web3
}

export const isAllowImageUrl = (url: string): boolean => {
  if (url.length > 0 && 
     (url.toLowerCase().startsWith('https://nft.metacityinvest.com') || 
      url.toLowerCase().startsWith('https://metacityinvest.com')) ) {
    return true
  }
  return false
}

export const toRounded = (num: string): string => {
  const n = Number(num)
  let m = Number((Math.abs(n) * 100).toPrecision(15))
  let rounded = Number((Math.round(m) / 100).toFixed(2))
  if (rounded < n) {
    rounded += 0.01 // round at 2nd precision
  }
  return rounded.toFixed(2)
}

export const toBasis = (percent: number): BN => {
  const basis = percent * 100
  return new BN(basis, 10)
}

export const fromBasis = (basisPercent: string): number => {
  const percent = parseInt(basisPercent)
  return percent / 100
}

export const toWei = (amount: string): BN => {
  if (!amount || amount.length == 0) {
    return new BN(0, 10)
  }

  if (isNaN(Number(amount))) {
    return new BN(0, 10)
  }

  const wei = Web3.utils.toWei(amount)
  return new BN(wei, 10)
}

export const toBytes32 = (str: string): string => {
  return Web3.utils.stringToHex(str)
}

export const fromBytes32 = (str: string): string => {
  return Web3.utils.hexToString(str)
}

export const toBN = (amount: string): BN => {
  try {
    return new BN(amount, 10)
  }catch(e){
    return new BN('0', 10)
  }
}

export const fromWei = (amount: string): string => {
  try {
    return Web3.utils.fromWei(amount)
  } catch (err:any) {
    return ''
  }
}

export const zeroBN = new BN(0, 10)
export const zeroAddr = '0x0000000000000000000000000000000000000000'
export const oneoneAddr = '0x1111111111111111111111111111111111111111'
export const ninenineAddr = '0x9999999999999999999999999999999999999999'

export const geoliteCountryToAlias = (country: string): string => {
  const countryLower = country.toLowerCase()
  if (countryLower == 'south africa') {
    return 'south-africa'
  } else if (countryLower == 'united states') {
    return 'usa'
  }
  return countryLower
}

export const countryExists = (country: string): boolean => {
  const countries: {[key: string]: boolean} = {
    'argentina': true,
    'brazil': true,
    'china': true,
    'colombia': true,
    'ghana': true,
    'india': true,
    'kenya': true,
    'nigeria': true,
    'pakistan': true,
    'philippines': true,
    'russia': true,
    'singapore': true,
    'south-africa': true,
    'tanzania': true,
    'thailand': true,
    'togo': true,
    'ukraine': true,
    'usa': true,
    'venezuela': true,
    'vietnam': true,
  }
  return countries[country.toLowerCase()]
}

export const capitalByCountry = (country: string): string => {
  const capitals = {
    'thailand': 'bangkok',
    'vietnam': 'ho-chi-minh',
    'india': 'new-delhi',
    'pakistan': 'islamabad',
    'ukraine': 'kyiv',
    'kenya': 'nairobi',
    'nigeria': 'abuja',
    'venezuela': 'caracas',
    'usa': 'washington',
    'argentina': 'buenos-aires',
    'colombia': 'bogota',
    'china': 'beijing',
    'brazil': 'brasilia',
    'philippines': 'manila',
    'south-africa': 'johannesburg',
    'ghana': 'accra',
    'russia': 'moscow',
    'tanzania': 'dar-es-salaam',
    'afghanistan': 'kabul',
    'singapore': 'singapore',
    'togo': 'lome',
  }

  return capitals[country.toLowerCase()]
}
