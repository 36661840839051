

















































import { Component, Provide, Watch, Prop } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { 
  FormMode,
  ListMode,
  newCityCell,
  PopOverItemType,
  PopOverMode,
} from './models'
import { 
  CitizenInfo, 
  newCitizenInfo, 
  MarketItem 
} from '~/types/meta_city'

@Component({
  components: {
  },
})
export default class ItemList extends CityBaseForm<any> {
  @Provide('form_name') form_name = 'city_form'

  get sellingItems(): MarketItem[] {
    const landId = `${this.selectedBusinessCell.id}`
    this.listItemsParams.landId = landId
    return this.listItems.callItems
  }

  get isBusinessForSell(): boolean {
    return !this.isSelectedMyBusiness && this.selectedBusinessCell.sell_price.length > 0
  }

  get isSelectedMyBusiness(): boolean {
    return this.selectedBusinessCell.owner_addr == this.walletAddress
  }

  get selectedBusinessOwnerInfo(): CitizenInfo {
    const selectedBusinessOwner = this.businessOwnerInfo
    if (selectedBusinessOwner.callItems.length > 0) {
      return selectedBusinessOwner.callItems[0]
    }
    return newCitizenInfo()
  }

  get numberOfNFTItems(): string {
    const landId = `${this.selectedBusinessCell.id}`
    this.listItemsParams.landId = landId
    if (this.listItemsCurrentAutonumber.callItems.length == 0){
      return '0'
    }
    return this.listItemsCurrentAutonumber.callItems[0]
  }


  onPrevPageClick() {
    let fromAutonumber = 0
    if (this.listItems.callItems.length > 0) {
      const len = this.listItems.callItems.length
      fromAutonumber = this.listItems.callItems[0].autonumber
      fromAutonumber += 9
      if (fromAutonumber > Number(this.numberOfNFTItems)) {
        // continue to first page
        fromAutonumber = 0
      }
    }

    const landId = `${this.selectedBusinessCell.id}`
    this.listItemsParams.landId = landId
    this.listItemsParams.fromAutonumber = fromAutonumber
    this.listItems.call()
  }

  onNextPageClick() {
    let fromAutonumber = 0
    if (this.listItems.callItems.length > 0) {
      const len = this.listItems.callItems.length
      fromAutonumber = this.listItems.callItems[len-1].autonumber
      fromAutonumber -= 1
      if (fromAutonumber <= 0) {
        // continue to first page
        fromAutonumber = 0
      }
    }

    const landId = `${this.selectedBusinessCell.id}`
    this.listItemsParams.landId = landId
    this.listItemsParams.fromAutonumber = fromAutonumber
    this.listItems.call()
  }

  onCloseItemListClick() {
    this.selectedBusinessCell = newCityCell()
    this.onCloseListClick()
  }

  onBuyItemClick(item: MarketItem) {
    
    const outOfStock = item.sold_items >= item.total_items

    if (!outOfStock) {
      this.formRepo.updateAttr(this.form_name, 'buyItemContentUrl', item.item_content_url)
      this.formRepo.updateAttr(this.form_name, 'landId', item.land_id)
      this.formRepo.updateAttr(this.form_name, 'buyItemAutonumber', item.autonumber)
      this.formRepo.updateAttr(this.form_name, 'buyAmountIn', item.current_price)
      this.formMode = FormMode.BuyItemForm
    } else {
      this.formRepo.updateAttr(this.form_name, 'bidItemName', item.item_name)
      this.formRepo.updateAttr(this.form_name, 'bidItemType', item.item_type)
      this.formRepo.updateAttr(this.form_name, 'bidItemContentUrl', item.item_content_url)
      this.formRepo.updateAttr(this.form_name, 'landId', item.land_id)
      this.formRepo.updateAttr(this.form_name, 'bidItemAutonumber', item.autonumber)
      this.formRepo.updateAttr(this.form_name, 'bidAmountIn', '')

      this.popOverItem = {
        item_id: `${item.autonumber}`,
        item_type: PopOverItemType.ItemOutOfStock,
        item_name: item.item_name,
        item_content_url: item.item_content_url,
        explain: 'This NFT is sold out, anyway, you can place Bid to buy from current owner',
        building_type: 0,
      }
      this.popOverMode = PopOverMode.ItemDetail
    }
  }

  onUpdateBusinessClick() {
    this.formMode = FormMode.SetupBusinessForm
  }

  onSellMyBusinessClick() {
    this.formMode = FormMode.SellBusinessForm
  }

  onBuyThisBusinessClick() {
    this.formMode = FormMode.BuyBusinessForm
  }
  
  onSellItemClick() {
    this.formMode = FormMode.SellItemForm
  }
}
