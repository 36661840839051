
export const VisitAttention = (onClose: any) => {
  return {
    title: 'Attention',
    text: `MetaCity is the experimentation project that try to create the growing economic in the metaverse. The creator of this project will not responsible to any lost that participants have by the project. Please participate in this experiment at your own risk, The whitepaper of this project is provided in the top menu. Enjoy and have fun.`,
    icon: 'warning',
    didClose: onClose,
  }
}

export const FormInvalid = (fieldName: string = 'Form field') => {
  return {
    title: 'Form field is invalid',
    text: `${fieldName} is invalid`,
    icon: 'warning'
  }
}

export const APIResponseError = (res: any) => {
  let errMsg = 'API response error'
  if (res.errorData?.response?.data?.error) {
    errMsg = `${res.errorData.response.data.error}`
  }
  return {
    title: 'Error',
    text: errMsg,
    icon: 'error'
  }
}

export const ContractResponseError = (res: any) => {
  return {
    title: 'Error',
    text: `${res.errorData}`,
    icon: 'error'
  }
}