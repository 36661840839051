









































































































































import { Component, Provide, Watch, Prop } from "vue-property-decorator";
import { CityBaseForm } from "./CityBaseForm";
import {
  FormMode,
  ListMode,
  newCityCell,
  PopupMode,
  ItemType,
  PopOverItemType,
  PopOverMode,
  PurchaseHistory,
} from "./models";
import { CitizenInfo, newCitizenInfo, MarketItem } from "~/types/meta_city";
import { toRounded, toBN, fromWei } from "~/utils/util";

@Component({
  components: {},
})
export default class BusinessDetail extends CityBaseForm<any> {
  @Provide("form_name") form_name = "city_form";

  get purchaseHistoryItems(): PurchaseHistory[] {
    return this.purchaseHistory.callItems;
  }

  get numberOfNFTItems(): string {
    const landId = `${this.selectedBusinessCell.id}`;
    this.listItemsParams.landId = landId;
    if (this.listItemsCurrentAutonumber.callItems.length == 0) {
      return "0";
    }
    return this.listItemsCurrentAutonumber.callItems[0];
  }

  get isBusinessForSell(): boolean {
    return (
      !this.isSelectedMyBusiness &&
      this.selectedBusinessCell.sell_price.length > 0
    );
  }

  get isSelectedMyBusiness(): boolean {
    if (
      !this.selectedBusinessCell ||
      !this.selectedBusinessCell.owner_addr ||
      !this.walletAddress
    ) {
      return false;
    }
    return (
      this.selectedBusinessCell.owner_addr.toLowerCase() ==
      this.walletAddress.toLowerCase()
    );
  }

  get hasBidding(): boolean {
    return (
      this.selectedBusinessCell &&
      this.selectedBusinessCell.buy_price &&
      this.selectedBusinessCell.buy_price.length > 0 &&
      !this.isBuyOrderExpired(this.selectedBusinessCell)
    );
  }

  get biddingPrice(): string {
    const cell = this.selectedBusinessCell;
    if (cell && cell.buy_price && cell.buy_price.length > 0) {
      const buyPrice = toBN(fromWei(`${cell.buy_price}`));
      return toRounded(`${buyPrice.toString()}`);
    }
    return "";
  }

  calHistoryAction(item: PurchaseHistory): string {
    if (item.seller_addr.length > 0) {
      return "Seller Accept Bidding:";
    }
    return "Buyer Accept Offer:";
  }

  calHistoryPrice(item: PurchaseHistory): string {
    return fromWei(item.price);
  }

  onSelectedBusinessClick() {
    if (!this.selectedBusinessCell || !this.selectedBusinessCell.owner_addr) {
      return;
    }
    const business = this.selectedBusinessCell;
    this.popOverItem = {
      item_id: business.id,
      item_type: PopOverItemType.Business,
      item_name: business.business_name,
      item_content_url: business.building_content_url,
      explain: "",
      building_type: business.business_type,
    };
    this.landLocationLoader.call();
    this.popOverMode = PopOverMode.ItemDetail;
  }

  onOwnerAvatarClick() {
    if (!this.selectedBusinessOwnerInfo) {
      return;
    }

    const citizen = this.selectedBusinessOwnerInfo;
    this.popOverItem = {
      item_id: `${citizen.avatar_item_id}`,
      item_type: PopOverItemType.Avatar,
      item_name: citizen.name,
      item_content_url: citizen.avatar_content_url,
      explain: "",
      building_type: 0,
    };
    this.popOverMode = PopOverMode.ItemDetail;
  }

  get selectedBusinessOwnerInfo(): CitizenInfo {
    const selectedBusinessOwner = this.businessOwnerInfo;
    if (selectedBusinessOwner.callItems.length > 0) {
      return selectedBusinessOwner.callItems[0];
    }
    return newCitizenInfo();
  }

  calItemPrice(item: MarketItem): string {
    return toRounded(item.current_price);
  }

  calItemImageSrc(item: MarketItem): string {
    return item.item_content_url;
  }

  onCloseBusinessDetailClick() {
    this.selectedBusinessCell = newCityCell();
    this.onClosePopupClick();
    this.onCloseListClick();
  }

  onViewNFTItemsClick() {
    this.listItems.call();
    this.popupMode = PopupMode.ItemList;
  }

  onUpdateBusinessClick() {
    this.formMode = FormMode.SetupBusinessForm;
  }

  onVoteThisBusinessClick() {
    this.formRepo.updateAttr(
      this.form_name,
      "tweetMessage",
      `I vote for ${this.selectedBusinessCell.business_name} (${this.selectedBusinessCell.id}) #GNMC`
    );
    this.formRepo.updateAttr(
      this.form_name,
      "tweetMyBusinessId",
      this.selectedBusinessCell.id
    );
    this.formMode = FormMode.TweetForm;
  }

  onSellMyBusinessClick() {
    this.formMode = FormMode.SellBusinessForm;
  }

  onAccepBiddingClick() {
    this.formMode = FormMode.AcceptBuyOrderForm;
  }

  onBuyThisBusinessClick() {
    this.formRepo.updateAttr(
      this.form_name,
      "businessBuyPrice",
      this.businessPrice(this.selectedBusinessCell)
    );
    this.formMode = FormMode.BuyBusinessForm;
  }

  onOfferBuyThisBusinessClick() {
    this.formRepo.updateAttr(this.form_name, "businessBuyPrice", "");
    this.formMode = FormMode.OfferBuyBusinessForm;
  }

  onSellItemClick() {
    this.formRepo.updateAttr(this.form_name, "itemName", "");
    this.formRepo.updateAttr(
      this.form_name,
      "itemType",
      ItemType.BrandedProduct
    );
    this.formRepo.updateAttr(this.form_name, "itemPrice", "");
    this.formRepo.updateAttr(this.form_name, "incrementalRate", "");
    this.formRepo.updateAttr(this.form_name, "totalItems", "");
    this.formRepo.updateAttr(this.form_name, "contentUrl", "");

    this.formMode = FormMode.SellItemForm;
  }
}
