






import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { Route } from 'vue-router'

@Component({
  components: {
  },
})
export default class MyLocation extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  country: string = ''
  city: string = ''
  block: number = 0

  @Watch('$route', { immediate: true, deep: true })
  async onRouteChange(newVal: Route) {
    if (newVal.name != 'country-city-block') {
      return
    }

    this.country = newVal.params.country
    this.city = newVal.params.city
    this.block = Number(newVal.params.block) + 1
  }
}

interface FormData {
  country: string
  city: string
  block: number
}
