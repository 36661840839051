import { Component, Prop, Provide } from 'vue-property-decorator'
import { BaseForm } from '~/core/BaseForm'
import { APILoader } from '~/loader/APILoader'
import { 
  getAPIEndpoint,
  getPathWithBaseURL,
} from '~/consts/config'
import { 
  FormMode,
  ListMode,
  PopupMode,
  PopOverMode,
  CityGrid,
  newCityGrid,
  CityGridParam,
  newCityGridParam,
  CityCell,
  newCityCell,
  BusinessType,
  Occupation,
  PopOverItem,
  PopOverItemType,
  newPopOverItem,
  LandLocation,
  PurchaseHistory,
} from './models'
import { AbiItem } from 'web3-utils'
import { WalletLoader } from '~/loader/WalletLoader'
import { ContractLoader } from '~/loader/ContractLoader'
import { 
  toWei, 
  toBasis, 
  toBytes32,
  fromWei,
  toBN, 
  zeroAddr,
  getNetwork,
  getChainId,
  isAllowImageUrl } from '~/utils/util'
import { 
    getAddressOfContract, 
    ContractNames,
    BlockIn1Day,
} from '~/consts/config'
import {
  ContractResponseError,
} from '~/consts/alerts'
import BN from 'bn.js'
import ERC20Contract from '~/build/contracts/ERC20.json'
import { ERC20 } from '~/types/web3-v1-contracts/ERC20'
import MetaLandContract from '~/build/contracts/MetaLand.json'
import { MetaLand } from '~/types/web3-v1-contracts/MetaLand'
import { MetaMarket } from '~/types/web3-v1-contracts/MetaMarket'
import MetaMarketContract from '~/build/contracts/MetaMarket.json'
import { SecondaryMarket } from '~/types/web3-v1-contracts/SecondaryMarket'
import SecondaryMarketContract from '~/build/contracts/SecondaryMarket.json'
import { MetaTweet } from '~/types/web3-v1-contracts/MetaTweet'
import MetaTweetContract from '~/build/contracts/MetaTweet.json'
import { MetaItem } from '~/types/web3-v1-contracts/MetaItem'
import MetaItemContract from '~/build/contracts/MetaItem.json'
import FaucetsContract from '~/build/contracts/Faucets.json'
import { Faucets } from '~/types/web3-v1-contracts/Faucets'
import BuyCityContract from '~/build/contracts/BuyCity.json'
import { BuyCity } from '~/types/web3-v1-contracts/BuyCity'
import MetaCityContract from '~/build/contracts/MetaCity.json'
import { MetaCity } from '~/types/web3-v1-contracts/MetaCity'
import { nextNBlockNumber } from '~/utils/test_util'
import { MetaCityHelper } from '~/utils/MetaCityHelper'
import { 
  CitizenInfo, 
  MarketItem,
  TweetItem,
  NFTItem,
  newNFTItem,
} from '~/types/meta_city'
import { toRounded } from '~/utils/util'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import { 
  DefaultAvatarURL,
  DefaultAvatarName,
  DefaultAvatarOccupation
} from '~/consts/config'
import { 
  CurrentLandPriceParams,
  newCurrentLandPriceParams, 
  BuyItemParams,
  newBuyItemParams,
  ListItemParams,
  newListItemParams,
  PostItemForSellParams,
  newPostItemForSellParams,
  MintFaucetsParams,
  newMintFaucetsParams,
  BuyMetaLandWithCityTokenParams,
  newBuyMetaLandWithCityTokenParams,
  AllowanceOfERC20TokenParams,
  newAllowanceOfERC20TokenParams,
  ApproveERC20TokenParams,
  newApproveERC20TokenParams,
  BuyCityTokenWithNativeTokenParams,
  newBuyCityTokenWithNativeTokenParams,
  RegisterCitizenParams,
  newRegisterCitizenParams,
  SetupBusinessParams,
  newSetupBusinessParams,
  CreateSellOrderParams,
  newCreateSellOrderParams,
  CreateBuyOrderParams,
  newCreateBuyOrderParams,
  AllowanceOfMetaItemForSecondaryMarketParams,
  newAllowanceOfMetaItemForSecondaryMarketParams,
  ApproveMetaItemForSecondaryMarketParams,
  newApproveMetaItemForSecondaryMarketParams,
  AllowanceOfMetaLandForMetaCityParams,
  newAllowanceOfMetaLandForMetaCityParams,
  ApproveMetaLandForMetaCityParams,
  newApproveMetaLandForMetaCityParams,
  BusinessOwnerInfoParams,
  newBusinessOwnerInfoParams,
  PostTweetParams,
  newPostTweetParams,
  ListTweetsParams,
  newListTweetsParams,
  MySellingItemsParams,
  newMySellingItemsParams,
  CityCellRefreshParams,
  newCityCellRefreshParams,
  MyItemsRefreshParams,
  newMyItemsRefreshParams,
  BuyNativeTokenWithCityTokenParams,
  newBuyNativeTokenWithCityTokenParams,
  AcceptBuyOrderParams,
  newAcceptBuyOrderParams,
  PurchaseHistoryParams,
  newPurchaseHistoryParams,
  BidItemParams,
  newBidItemParams,
  AcceptItemBuyOrderParams,
  newAcceptItemBuyOrderParams,
} from './params'

@Component
export class CityBaseForm<T> extends BaseForm<T> {

  @Provide('form_name') form_name = 'city_form'

  get popOverItem(): PopOverItem {
    return this.formRepo.getAttr(this.form_name, 'popover_item') as PopOverItem || newPopOverItem()
  }

  set popOverItem(val: PopOverItem) {
    this.formRepo.updateAttr(this.form_name, 'popover_item', val)
  }

  get popOverMode(): PopOverMode {
    return this.formRepo.getAttr(this.form_name, 'popover_mode') as PopOverMode || PopOverMode.None
  }

  set popOverMode(val: PopOverMode) {
    this.formRepo.updateAttr(this.form_name, 'popover_mode', val)
  }

  get isShowPopOver(): boolean {
    return this.popOverMode == PopOverMode.ItemDetail
  }

  get isShowBanner(): boolean {
    return this.popOverMode == PopOverMode.Banner
  }

  closePopOver() {
    this.popOverMode = PopOverMode.None
  }

  loginMetamask() {
    this.walletLoader(async ()=>{
      await this.switchChain(getChainId())
      // Load owner assets
      this.myItemsLoader.call()
      this.balanceOfCityToken.call()
      this.citizenInfo.call()

    }).call()
  }

  get isLoading(): boolean {
    return this.formRepo.getAttr(this.form_name, 'is_loading') as boolean || false
  }

  set isLoading(val: boolean) {
    this.formRepo.updateAttr(this.form_name, 'is_loading', val)
  }

  get isShowLogin(): boolean {
    return this.formRepo.getAttr(this.form_name, 'is_show_login') as boolean || false
  }

  set isShowLogin(val: boolean) {
    this.formRepo.updateAttr(this.form_name, 'is_show_login', val)
  }

  showLoginDialog() {
    this.isShowLogin = true
  }

  hideLoginDialog() {
    this.isShowLogin = false
  }

  onCloseFormClick() {
    this.formMode = FormMode.SelectCityForm
  }

  onCloseListClick() {
    this.listMode = ListMode.None
    this.formMode = FormMode.SelectCityForm
  }

  onClosePopupClick() {
    this.popupMode = PopupMode.None
    this.formMode = FormMode.SelectCityForm
  }

  get isItemPopupMode(): boolean {
    return this.popupMode == PopupMode.ItemList
  }

  get isBusinessDetailMode(): boolean {
    return this.listMode == ListMode.BusinessDetail
  }

  get isTweetListMode(): boolean {
    return this.listMode == ListMode.TweetList
  }

  get isMyAssetListMode(): boolean {
    return this.listMode == ListMode.MyAssetList
  }

  get isSellBusinessFormMode(): boolean {
    return this.formMode == FormMode.SellBusinessForm
  }

  get isAcceptBuyOrderFormMode(): boolean {
    return this.formMode == FormMode.AcceptBuyOrderForm
  }

  get isAcceptItemBuyOrderFormMode(): boolean {
    return this.formMode == FormMode.AcceptItemBuyOrderForm
  }

  get isBuyBusinessFormMode(): boolean {
    return this.formMode == FormMode.BuyBusinessForm || this.formMode == FormMode.OfferBuyBusinessForm
  }

  get isSetupBusinessFormMode(): boolean {
    return this.formMode == FormMode.SetupBusinessForm
  }

  get isTweetFormMode(): boolean {
    return this.formMode == FormMode.TweetForm
  }

  get isRegisterCitizenFormMode(): boolean {
    return this.formMode == FormMode.RegisterCitizenForm
  }

  get isGetCityTokenFormMode(): boolean {
    return this.formMode == FormMode.GetCityTokenForm
  }

  get isGetNativeTokenFormMode(): boolean {
    return this.formMode == FormMode.GetNativeTokenForm
  }

  get isBuyLandFormMode(): boolean {
    return this.formMode == FormMode.BuyLandForm
  }

  get isBuyItemFormMode(): boolean {
    return this.formMode == FormMode.BuyItemForm
  }

  get isBidItemFormMode(): boolean {
    return this.formMode == FormMode.BidItemForm
  }

  get isSellItemFormMode(): boolean {
    return this.formMode == FormMode.SellItemForm
  }
  
  get cityGridParam(): CityGridParam {
    return this.formRepo.getAttr(this.form_name, 'city_grid_param') as CityGridParam || newCityGridParam()
  }

  set cityGridParam(val: CityGridParam) {
    this.formRepo.updateAttr(this.form_name, 'city_grid_param', val)
  }

  get hoverCell(): CityCell {
    return this.formRepo.getAttr(this.form_name, 'hover_cell') as CityCell || newCityCell()
  }

  set hoverCell(val: CityCell) {
    this.formRepo.updateAttr(this.form_name, 'hover_cell', val)
  }

  get selectedMyNFT(): NFTItem {
    return this.formRepo.getAttr(this.form_name, 'selected_my_nft') as NFTItem || newNFTItem()
  }

  set selectedMyNFT(val: NFTItem) {
    this.formRepo.updateAttr(this.form_name, 'selected_my_nft', val)
  }

  get selectedBusinessCell(): CityCell {
    return this.formRepo.getAttr(this.form_name, 'selected_business_cell') as CityCell || newCityCell()
  }

  set selectedBusinessCell(val: CityCell) {
    this.formRepo.updateAttr(this.form_name, 'selected_business_cell', val)
  }

  get selectedCell(): CityCell {
    return this.formRepo.getAttr(this.form_name, 'selected_cell') as CityCell || newCityCell()
  }

  set selectedCell(val: CityCell) {
    this.formRepo.updateAttr(this.form_name, 'selected_cell', val)
  }

  get popupMode(): PopupMode {
    return this.formRepo.getAttr(this.form_name, 'popup_mode') as PopupMode || PopupMode.None
  }

  set popupMode(val: PopupMode) {
    this.formRepo.updateAttr(this.form_name, 'popup_mode', val)
  }

  get listMode(): ListMode {
    return this.formRepo.getAttr(this.form_name, 'list_mode') as ListMode || ListMode.None
  }

  set listMode(val: ListMode) {
    this.formRepo.updateAttr(this.form_name, 'list_mode', val)
  }

  get formMode(): FormMode {
    return this.formRepo.getAttr(this.form_name, 'form_mode') as FormMode || FormMode.SelectCityForm
  }

  set formMode(val: FormMode) {
    this.formRepo.updateAttr(this.form_name, 'form_mode', val)
  }

  get bidItemParams(): BidItemParams {
    return this.formRepo.getAttr(this.form_name, 'market_bid_item_params') as BidItemParams || newBidItemParams()
  }
  
  set bidItemParams(val: BidItemParams) {
    this.formRepo.updateAttr(this.form_name, 'market_bid_item_params', val)
  }

  get bidItem(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `market_bid_item`,
      onCallSuccess: this.bidItemParams.success,
      onCallError: this.bidItemParams.err,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.SecondaryMarket)
        const contract = this.getSecondaryMarketContract(addr)

        try {
          const landId = this.bidItemParams.landId;
          const itemName = toBytes32(this.bidItemParams.itemName)
          const itemType = this.bidItemParams.itemType
          const itemContentUrl = this.bidItemParams.itemContentUrl
          const buyPrice = toWei(`${this.bidItemParams.buyPrice}`)

          let endBlock = await nextNBlockNumber(this.$web3, BlockIn1Day * 365) // default order expired = 365 days
          if (this.bidItemParams.expiredInNDays > 0) {
            endBlock =  await nextNBlockNumber(this.$web3, BlockIn1Day * this.bidItemParams.expiredInNDays)
          }

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.createBuyOrder(
            landId,
            itemName,
            itemType,
            itemContentUrl, 
            buyPrice, 
            endBlock).estimateGas()
          
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.createBuyOrder(
            landId,
            itemName,
            itemType,
            itemContentUrl, 
            buyPrice, 
            endBlock).send(params)
            
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get purchaseHistoryParams(): PurchaseHistoryParams {
    return this.formRepo.getAttr(this.form_name, 'purchase_history_params') as PurchaseHistoryParams || newPurchaseHistoryParams()
  }
  
  set purchaseHistoryParams(val: PurchaseHistoryParams) {
    this.formRepo.updateAttr(this.form_name, 'purchase_history_params', val)
  }

  get purchaseHistory(): APILoader<PurchaseHistory> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'purchase_history',
      baseURL: getAPIEndpoint(`/api/purchases/${this.purchaseHistoryParams.landId}`),
      params,
    }))
  }

  get myItemsLoader(): APILoader<NFTItem> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'owner_items',
      baseURL: getAPIEndpoint(`/api/items/${this.walletAddress}`),
      params,
    }))
  }

  get myBusinessLoader(): APILoader<any> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'owner_business',
      baseURL: getAPIEndpoint(`/api/lands/${this.walletAddress}`),
      params,
    }))
  }

  get mySellingItemsParams(): MySellingItemsParams {
    return this.formRepo.getAttr(this.form_name, 'my_selling_items_params') as MySellingItemsParams || newMySellingItemsParams()
  }
  
  set mySellingItemsParams(val: MySellingItemsParams) {
    this.formRepo.updateAttr(this.form_name, 'my_selling_items_params', val)
  }

  get mySellingItems(): ContractLoader<MarketItem> {
    return new ContractLoader<MarketItem>(this, (_: any) => ({
      stateKey: 'my_selling_items',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaMarket)
        const contract = this.getMetaMarketContract(addr)

        try {
          const res = await contract.methods.listItems(
            this.mySellingItemsParams.landId, 
            this.mySellingItemsParams.fromAutonumber).call()

          const items: MarketItem[] = []
          for (let i=0; i<res.length; i++) {
            const item = MetaCityHelper.transformMarketItem(res[i])
            if (item.autonumber > 0) {
              items.push(item)
            }
          }
          
          return items
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get landLocationLoader(): APILoader<LandLocation> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'land_location',
      baseURL: getAPIEndpoint(`/api/location/${this.popOverItem.item_id}`),
      params,
    }))
  }

  get miniMapLoader(): APILoader<any> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'city_map',
      baseURL: getAPIEndpoint(`/api/map/${this.cityGridParam.country}/${this.cityGridParam.city}`),
      params,
    }))
  }

  get citiesLoader(): APILoader<any> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'cities',
      baseURL: getAPIEndpoint(`/api/cities/${this.cityGridParam.country}`),
      params,
    }))
  }

  get cityGrid(): CityGrid {
    if (this.cityGridLoader.callItems && 
        this.cityGridLoader.callItems.length > 0) {
      return this.cityGridLoader.callItems[0]
    }
    return newCityGrid()
  }

  get cityGridLoader(): APILoader<CityGrid> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'city_grid',
      baseURL: getAPIEndpoint(`/api/cities/${this.cityGridParam.country}/${this.cityGridParam.city}/${this.cityGridParam.block}`),
      params,
    }))
  }

  get cityCellRefreshParams(): CityCellRefreshParams {
    return this.formRepo.getAttr(this.form_name, 'city_cell_refresh_params') as CityCellRefreshParams || newCityCellRefreshParams()
  }
  
  set cityCellRefreshParams(val: CityCellRefreshParams) {
    this.formRepo.updateAttr(this.form_name, 'city_cell_refresh_params', val)
  }

  get cityCellRefresh(): APILoader<CityCell> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'city_cell_refresh',
      baseURL: getAPIEndpoint(`/api/refresh/citycell/${this.cityCellRefreshParams.landId}`),
      params,
    }))
  }

  get myItemsRefreshParams(): MyItemsRefreshParams {
    return this.formRepo.getAttr(this.form_name, 'my_items_refresh_params') as MyItemsRefreshParams || newMyItemsRefreshParams()
  }
  
  set myItemsRefreshParams(val: MyItemsRefreshParams) {
    this.formRepo.updateAttr(this.form_name, 'my_items_refresh_params', val)
  }

  get myItemsRefresh(): APILoader<any> {
    const params = {}
    return new APILoader(this, (data: any) => ({
      stateKey: 'my_items_refresh',
      baseURL: getAPIEndpoint(`/api/refresh/myitems/${this.myItemsRefreshParams.blockNumber}`),
      params,
    }))
  }

  businessPrice(cell: CityCell): string {
    if (cell && cell.sell_price && cell.sell_price.length > 0) {
      const sellPrice = toBN(fromWei(`${cell.sell_price}`))
      return toRounded(`${sellPrice.toString()}`)
    }
    return ''
  }

  buyPrice(cell: CityCell): string {
    if (cell && cell.buy_price && cell.buy_price.length > 0) {
      const buyPrice = toBN(fromWei(`${cell.buy_price}`))
      return toRounded(`${buyPrice.toString()}`)
    }
    return ''
  }

  calItemPrice(item: MarketItem): string {
    return toRounded(item.current_price)
  }

  calItemImageSrc(item: MarketItem): string {
    return item.item_content_url
  }

  calAvatarImage(citizen: CitizenInfo): string {
    if (isAllowImageUrl(citizen.avatar_content_url)) {
      return citizen.avatar_content_url
    }
    return DefaultAvatarURL
  }

  calAvatarName(citizen: CitizenInfo): string {
    if (citizen.name.length > 0) {
      return citizen.name
    }
    return DefaultAvatarName
  }

  calAvatarOccupation(citizen: CitizenInfo): string {
    if (citizen.occupation > 0) {
      return MetaCityHelper.parseOccupation(citizen.occupation)
    }
    return DefaultAvatarOccupation
  }

  calBuildingImage(cell: CityCell): string {
    if (isAllowImageUrl(cell.building_content_url)) {
      return cell.building_content_url
    }
    if (cell.building_type.length > 0) {
      return getPathWithBaseURL(`/${cell.building_type}.png`)
    }
    return ''
  }

  isSellOrderExpired(cell: CityCell): boolean {
    if (this.currentBlock.callItems.length <= 0) {
      return false
    }

    const curBlock = this.currentBlock.callItems[0]
    return Number(cell.sell_ended_block) < curBlock
  }

  isBuyOrderExpired(cell: CityCell): boolean {
    if (this.currentBlock.callItems.length <= 0) {
      return false
    }

    const curBlock = this.currentBlock.callItems[0]
    return Number(cell.buy_ended_block) < curBlock
  }

  countBuildingTypes(types: string[]): number {
    let counter = 0
    for (let i=0; i<this.cityGrid.cells.length; i++) {
      for (let j=0; j<types.length; j++) {
        if (this.cityGrid.cells[i].building_type.startsWith(types[j])) {
          counter++
        }
      }
    }
    return counter
  }

  endContractTransactionError(loader: ContractLoader) {
    this.isLoading = false
    if (loader.callStatus) {
      this.alert(ContractResponseError(loader.callStatus))
    }
  }

  get listTweetsParams(): ListTweetsParams {
    return this.formRepo.getAttr(this.form_name, 'list_tweets_params') as ListTweetsParams || newListTweetsParams()
  }
  
  set listTweetsParams(val: ListTweetsParams) {
    this.formRepo.updateAttr(this.form_name, 'list_tweets_params', val)
  }

  get currentBlock(): ContractLoader<number> {
    return new ContractLoader<number>(this, (_: any) => ({
      stateKey: 'current_block',
      handle: async () => {
        try {
          let block = await this.$web3.eth.getBlockNumber()
          return block
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get listTweets(): ContractLoader<TweetItem> {
    return new ContractLoader<TweetItem>(this, (_: any) => ({
      stateKey: 'list_tweets',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaTweet)
        const contract = this.getMetaTweetContract(addr)

        try {
          const res = await contract.methods.listTweets(
            this.listTweetsParams.fromAutonumber).call()

          const items: TweetItem[] = []
          for (let i=0; i<res.length; i++) {
            const item = MetaCityHelper.transformTweetItem(res[i])
            if (item.autonumber > 0) {
              items.push(item)
            }
          }
          
          return items
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get listTweetsLastAutonumber(): ContractLoader<any> {
    return new ContractLoader<any>(this, (_: any) => ({
      stateKey: 'list_tweets_last_autonumber',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaTweet)
        const contract = this.getMetaTweetContract(addr)

        try {
          const res = await contract.methods.lastTweetAutonumber().call()
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get postTweetParams(): PostTweetParams {
    return this.formRepo.getAttr(this.form_name, 'post_tweet_params') as PostTweetParams || newPostTweetParams()
  }
  
  set postTweetParams(val: PostTweetParams) {
    this.formRepo.updateAttr(this.form_name, 'post_tweet_params', val)
  }

  get postTweet(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `post_tweet`,
      onCallSuccess: this.postTweetParams.success,
      onCallError: this.postTweetParams.err,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaTweet)
        const contract = this.getMetaTweetContract(addr)

        try {
          const message = this.postTweetParams.message
          const fee = toWei(`${this.postTweetParams.fee}`)
          let landId = this.postTweetParams.landId
          if (!landId || landId.length == 0) {
            landId = '0'
          }
          let itemId = this.postTweetParams.itemId
          if (!itemId || itemId.length == 0) {
            itemId = '0'
          }
          let sellId = this.postTweetParams.sellId
          if (!sellId || sellId.length == 0) {
            sellId = '0'
          }
          let toAddr = this.postTweetParams.toAddress
          if (!toAddr || toAddr.length == 0) {
            toAddr = zeroAddr
          }

          const gasPrice = await this.$web3.eth.getGasPrice()

          const gas = await contract.methods.postTweet(
            message, itemId, landId, sellId, toAddr, fee).estimateGas()
         
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.postTweet(
            message, itemId, landId, sellId, toAddr, fee).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get approveMetaItemForSecondaryMarketParams(): ApproveMetaItemForSecondaryMarketParams {
    return this.formRepo.getAttr(this.form_name, 'metaitem_for_secondarymarket_approve_params') as ApproveMetaItemForSecondaryMarketParams || newApproveMetaItemForSecondaryMarketParams()
  }
  
  set approveMetaItemForSecondaryMarketParams(val: ApproveMetaItemForSecondaryMarketParams) {
    this.formRepo.updateAttr(this.form_name, 'metaitem_for_secondarymarket_approve_params', val)
  }

  get approveMetaItemForSecondaryMarket(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `metaitem_for_secondarymarket_approve`,
      onCallSuccess: this.approveMetaItemForSecondaryMarketParams.success,
      onCallError: this.approveMetaItemForSecondaryMarketParams.err,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaItem)
        const metaItem = this.getMetaItemContract(addr)
        const secondaryMarketAddr = getAddressOfContract(ContractNames.SecondaryMarket)

        try {
          const itemId = this.approveMetaItemForSecondaryMarketParams.itemId

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await metaItem.methods.approve(secondaryMarketAddr, itemId).estimateGas()
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await metaItem.methods.approve(secondaryMarketAddr, itemId).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get allowanceOfMetaItemForSecondaryMarketParams(): AllowanceOfMetaItemForSecondaryMarketParams {
    return this.formRepo.getAttr(this.form_name, 'metaitem_for_secondarymarket_allowance_params') as AllowanceOfMetaItemForSecondaryMarketParams || newAllowanceOfMetaItemForSecondaryMarketParams()
  }
  
  set allowanceOfMetaItemForSecondaryMarketParams(val: AllowanceOfMetaItemForSecondaryMarketParams) {
    this.formRepo.updateAttr(this.form_name, 'metaitem_for_secondarymarket_allowance_params', val)
  }

  get allowanceOfMetaItemForSecondaryMarket(): ContractLoader<boolean> {
    return new ContractLoader<boolean>(this, (_: any) => ({
      stateKey: 'metaitem_for_secondarymarket_allowance',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaItem)
        const metaItem = this.getMetaItemContract(addr)
        const secondaryMarketAddr = getAddressOfContract(ContractNames.SecondaryMarket)

        try {
          const itemId = this.allowanceOfMetaItemForSecondaryMarketParams.itemId
          const res = await metaItem.methods.getApproved(itemId).call()
          return res.toLowerCase() == secondaryMarketAddr.toLowerCase()
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get acceptItemBuyOrderParams(): AcceptItemBuyOrderParams {
    return this.formRepo.getAttr(this.form_name, 'accept_item_buy_order_params') as AcceptItemBuyOrderParams || newAcceptItemBuyOrderParams()
  }
  
  set acceptItemBuyOrderParams(val: AcceptItemBuyOrderParams) {
    this.formRepo.updateAttr(this.form_name, 'accept_item_buy_order_params', val)
  }

  get acceptItemBuyOrder(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `accept_item_buy_order`,
      onCallSuccess: this.acceptItemBuyOrderParams.success,
      onCallError: this.acceptItemBuyOrderParams.err,
      handle: async () => {

        const addr = getAddressOfContract(ContractNames.SecondaryMarket)
        const contract = this.getSecondaryMarketContract(addr)

        try {
          const sellPriceInWei = toWei(`${this.acceptItemBuyOrderParams.sellPrice}`)
          const itemId = this.acceptItemBuyOrderParams.itemId
          const buyOrderAutonumber = this.acceptItemBuyOrderParams.buyOrderAutonumber

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.acceptBuyOrder(
            itemId, buyOrderAutonumber, sellPriceInWei).estimateGas()

          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.acceptBuyOrder(
            itemId, buyOrderAutonumber, sellPriceInWei).send(params)

          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get approveMetaLandForMetaCityParams(): ApproveMetaLandForMetaCityParams {
    return this.formRepo.getAttr(this.form_name, 'metaland_for_metacity_approve_params') as ApproveMetaLandForMetaCityParams || newApproveMetaLandForMetaCityParams()
  }
  
  set approveMetaLandForMetaCityParams(val: ApproveMetaLandForMetaCityParams) {
    this.formRepo.updateAttr(this.form_name, 'metaland_for_metacity_approve_params', val)
  }

  get approveMetaLandForMetaCity(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `metaland_for_metacity_approve`,
      onCallSuccess: this.approveMetaLandForMetaCityParams.success,
      onCallError: this.approveMetaLandForMetaCityParams.err,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaLand)
        const metaLand = this.getMetaLandContract(addr)
        const metaCityAddr = getAddressOfContract(ContractNames.MetaCity)

        try {
          const landId = this.approveMetaLandForMetaCityParams.landId

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await metaLand.methods.approve(metaCityAddr, landId).estimateGas()
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await metaLand.methods.approve(metaCityAddr, landId).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get allowanceOfMetaLandForMetaCityParams(): AllowanceOfMetaLandForMetaCityParams {
    return this.formRepo.getAttr(this.form_name, 'metaland_for_metacity_allowance_params') as AllowanceOfMetaLandForMetaCityParams || newAllowanceOfMetaLandForMetaCityParams()
  }
  
  set allowanceOfMetaLandForMetaCityParams(val: AllowanceOfMetaLandForMetaCityParams) {
    this.formRepo.updateAttr(this.form_name, 'metaland_for_metacity_allowance_params', val)
  }

  get allowanceOfMetaLandForMetaCity(): ContractLoader<boolean> {
    return new ContractLoader<boolean>(this, (_: any) => ({
      stateKey: 'metaland_for_metacity_allowance',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaLand)
        const metaLand = this.getMetaLandContract(addr)
        const metaCityAddr = getAddressOfContract(ContractNames.MetaCity)

        try {
          const landId = this.allowanceOfMetaLandForMetaCityParams.landId
          const res = await metaLand.methods.getApproved(landId).call()
          return res.toLowerCase() == metaCityAddr.toLowerCase()
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get createBuyOrderParams(): CreateBuyOrderParams {
    return this.formRepo.getAttr(this.form_name, 'create_buy_order_params') as CreateBuyOrderParams || newCreateBuyOrderParams()
  }
  
  set createBuyOrderParams(val: CreateBuyOrderParams) {
    this.formRepo.updateAttr(this.form_name, 'create_buy_order_params', val)
  }

  get createBuyOrder(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `create_buy_order`,
      onCallSuccess: this.createBuyOrderParams.success,
      onCallError: this.createBuyOrderParams.err,
      handle: async () => {

        const addr = getAddressOfContract(ContractNames.MetaCity)
        const contract = this.getMetaCityContract(addr)

        try {
          const buyPriceInWei = toWei(`${this.createBuyOrderParams.buyPrice}`)
          const landId = this.createBuyOrderParams.landId
          const newBusinessName = toBytes32(this.createBuyOrderParams.newBusinessName)
          const newBusinessType = this.createBuyOrderParams.newBusinessType

          let endBlock = await nextNBlockNumber(this.$web3, BlockIn1Day * 365) // default order expired = 365 days
          if (this.createBuyOrderParams.expiredInNDays > 0) {
            endBlock =  await nextNBlockNumber(this.$web3, BlockIn1Day * this.createBuyOrderParams.expiredInNDays)
          }

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.createBuyOrder(
            landId, buyPriceInWei, endBlock, newBusinessName, newBusinessType).estimateGas()

          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.createBuyOrder(
            landId, buyPriceInWei, endBlock, newBusinessName, newBusinessType).send(params)

          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get acceptBuyOrderParams(): AcceptBuyOrderParams {
    return this.formRepo.getAttr(this.form_name, 'accept_buy_order_params') as AcceptBuyOrderParams || newAcceptBuyOrderParams()
  }
  
  set acceptBuyOrderParams(val: AcceptBuyOrderParams) {
    this.formRepo.updateAttr(this.form_name, 'accept_buy_order_params', val)
  }

  get acceptBuyOrder(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `accept_buy_order`,
      onCallSuccess: this.acceptBuyOrderParams.success,
      onCallError: this.acceptBuyOrderParams.err,
      handle: async () => {

        const addr = getAddressOfContract(ContractNames.MetaCity)
        const contract = this.getMetaCityContract(addr)

        try {
          const sellPriceInWei = toWei(`${this.acceptBuyOrderParams.sellPrice}`)
          const landId = this.acceptBuyOrderParams.landId
          const buyOrderAutonumber = this.acceptBuyOrderParams.buyOrderAutonumber

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.acceptBuyOrder(
            landId, buyOrderAutonumber, sellPriceInWei).estimateGas()

          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.acceptBuyOrder(
            landId, buyOrderAutonumber, sellPriceInWei).send(params)

          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get createSellOrderParams(): CreateSellOrderParams {
    return this.formRepo.getAttr(this.form_name, 'create_sell_order_params') as CreateSellOrderParams || newCreateSellOrderParams()
  }
  
  set createSellOrderParams(val: CreateSellOrderParams) {
    this.formRepo.updateAttr(this.form_name, 'create_sell_order_params', val)
  }

  get createSellOrder(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `create_sell_order`,
      onCallSuccess: this.createSellOrderParams.success,
      onCallError: this.createSellOrderParams.err,
      handle: async () => {

        const addr = getAddressOfContract(ContractNames.MetaCity)
        const contract = this.getMetaCityContract(addr)

        try {
          const sellPriceInWei = toWei(`${this.createSellOrderParams.sellPrice}`)
          const landId = this.createSellOrderParams.landId

          let endBlock = await nextNBlockNumber(this.$web3, BlockIn1Day * 365) // default order expired = 365 days
          if (this.createSellOrderParams.expiredInNDays > 0) {
            endBlock =  await nextNBlockNumber(this.$web3, BlockIn1Day * this.createSellOrderParams.expiredInNDays)
          }

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.createSellOrder(
            landId, sellPriceInWei, endBlock).estimateGas()

          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.createSellOrder(
            landId, sellPriceInWei, endBlock).send(params)

          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get setupBusinessParams(): SetupBusinessParams {
    return this.formRepo.getAttr(this.form_name, 'setup_business_params') as SetupBusinessParams || newSetupBusinessParams()
  }
  
  set setupBusinessParams(val: SetupBusinessParams) {
    this.formRepo.updateAttr(this.form_name, 'setup_business_params', val)
  }

  get setupBusiness(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `setup_business`,
      onCallSuccess: this.setupBusinessParams.success,
      onCallError: this.setupBusinessParams.err,
      handle: async () => {

        const addr = getAddressOfContract(ContractNames.MetaCity)
        const contract = this.getMetaCityContract(addr)

        try {
          const feeInWei = toWei(`${this.setupBusinessParams.fee}`)
          const landId = this.setupBusinessParams.landId
          const businessName = toBytes32(this.setupBusinessParams.businessName)
          const businessType = this.setupBusinessParams.businessType
          let buildingItemId = this.setupBusinessParams.buildingItemId
          if (buildingItemId == null || buildingItemId.length == 0) {
            buildingItemId = '0'
          }

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.setupBusiness(
            landId, businessName, businessType, buildingItemId, feeInWei).estimateGas()

          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.setupBusiness(
            landId, businessName, businessType, buildingItemId, feeInWei).send(params)

          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get registerCitizenParams(): RegisterCitizenParams {
    return this.formRepo.getAttr(this.form_name, 'register_citizen_params') as RegisterCitizenParams || newRegisterCitizenParams()
  }
  
  set registerCitizenParams(val: RegisterCitizenParams) {
    this.formRepo.updateAttr(this.form_name, 'register_citizen_params', val)
  }

  get registerCitizen(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `register_citizen`,
      onCallSuccess: this.registerCitizenParams.success,
      onCallError: this.registerCitizenParams.err,
      handle: async () => {

        const addr = getAddressOfContract(ContractNames.MetaCity)
        const contract = this.getMetaCityContract(addr)

        try {
          const feeInWei = toWei(`${this.registerCitizenParams.fee}`)
          const name = toBytes32(this.registerCitizenParams.name)
          const occupation = this.registerCitizenParams.occupation
          
          let avatarItemId = this.registerCitizenParams.avatarItemId
          if (avatarItemId == null || avatarItemId.length == 0) {
            avatarItemId = '0'
          }

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.registerCitizen(
            name, occupation, avatarItemId, feeInWei).estimateGas()

          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.registerCitizen(
            name, occupation, avatarItemId, feeInWei).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get currentLandPriceParams(): CurrentLandPriceParams {
    return this.formRepo.getAttr(this.form_name, 'current_land_price_params') as CurrentLandPriceParams || newCurrentLandPriceParams()
  }
  
  set currentLandPriceParams(val: CurrentLandPriceParams) {
    this.formRepo.updateAttr(this.form_name, 'current_land_price_params', val)
  }

  get currentLandPrice(): ContractLoader<string> {
    return new ContractLoader<string>(this, (_: any) => ({
      stateKey: 'current_land_price',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaCity)
        const metaCity = this.getMetaCityContract(addr)

        try {
          return await metaCity.methods.currentLandPrice(this.currentLandPriceParams.landId).call()
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get businessOwnerInfoParams(): BusinessOwnerInfoParams {
    return this.formRepo.getAttr(this.form_name, 'business_owner_info_params') as BusinessOwnerInfoParams || newBusinessOwnerInfoParams()
  }
  
  set businessOwnerInfoParams(val: BusinessOwnerInfoParams) {
    this.formRepo.updateAttr(this.form_name, 'business_owner_info_params', val)
  }

  get businessOwnerInfo(): ContractLoader<CitizenInfo> {
    return new ContractLoader<CitizenInfo>(this, (_: any) => ({
      stateKey: 'business_owner_info',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaCity)
        const metaCity = this.getMetaCityContract(addr)
        const ownerAddr = this.businessOwnerInfoParams.ownerAddr

        try {
          const res = await metaCity.methods.citizenInfo(ownerAddr).call()
          return MetaCityHelper.transformCitizenInfo(res)
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get citizenInfo(): ContractLoader<CitizenInfo> {
    return new ContractLoader<CitizenInfo>(this, (_: any) => ({
      stateKey: 'citizen_info',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaCity)
        const metaCity = this.getMetaCityContract(addr)

        try {
          const res = await metaCity.methods.citizenInfo(this.walletAddress).call()
          return MetaCityHelper.transformCitizenInfo(res)
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get buyItemParams(): BuyItemParams {
    return this.formRepo.getAttr(this.form_name, 'market_buy_item_params') as BuyItemParams || newBuyItemParams()
  }
  
  set buyItemParams(val: BuyItemParams) {
    this.formRepo.updateAttr(this.form_name, 'market_buy_item_params', val)
  }

  get buyItem(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `market_buy_item`,
      onCallSuccess: this.buyItemParams.success,
      onCallError: this.buyItemParams.err,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaMarket)
        const contract = this.getMetaMarketContract(addr)

        try {
          const amountInWei = toWei(`${this.buyItemParams.amountIn}`)

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.buyItem(
            this.buyItemParams.landId, this.buyItemParams.autonumber, amountInWei).estimateGas()
          
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.buyItem(
            this.buyItemParams.landId, this.buyItemParams.autonumber, amountInWei).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get listItemsParams(): ListItemParams {
    return this.formRepo.getAttr(this.form_name, 'market_list_items_params') as ListItemParams || newListItemParams()
  }
  
  set listItemsParams(val: ListItemParams) {
    this.formRepo.updateAttr(this.form_name, 'market_list_items_params', val)
  }

  get listItems(): ContractLoader<MarketItem> {
    return new ContractLoader<MarketItem>(this, (_: any) => ({
      stateKey: `market_list_items_${this.listItemsParams.landId.toLowerCase()}`,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaMarket)
        const contract = this.getMetaMarketContract(addr)
        try {
          const res = await contract.methods.listItems(
            this.listItemsParams.landId, 
            this.listItemsParams.fromAutonumber).call()

          const items: MarketItem[] = []
          for (let i=0; i<res.length; i++) {
            const item = MetaCityHelper.transformMarketItem(res[i])
            if (item.autonumber > 0) {
              items.push(item)
            }
          }
          
          return items
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get listItemsCurrentAutonumber(): ContractLoader<any> {
    return new ContractLoader<any>(this, (_: any) => ({
      stateKey: `market_list_items_current_autonumber_${this.listItemsParams.landId.toLowerCase()}`,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaMarket)
        const contract = this.getMetaMarketContract(addr)

        try {
          const res = await contract.methods.currentItemAutonumber(this.listItemsParams.landId).call()
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get postItemForSellParams(): PostItemForSellParams {
    return this.formRepo.getAttr(this.form_name, 'market_post_item_for_sell_params') as PostItemForSellParams || newPostItemForSellParams()
  }
  
  set postItemForSellParams(val: PostItemForSellParams) {
    this.formRepo.updateAttr(this.form_name, 'market_post_item_for_sell_params', val)
  }

  get postItemForSell(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `market_post_item_for_sell`,
      onCallSuccess: this.postItemForSellParams.success,
      onCallError: this.postItemForSellParams.err,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaMarket)
        const contract = this.getMetaMarketContract(addr)

        try {
          const itemPriceWei = toWei(`${this.postItemForSellParams.itemPrice}`)
          const itemNameBytes = toBytes32(this.postItemForSellParams.itemName)
          const priceIncrRateBasis = toBasis(this.postItemForSellParams.priceIncrRate)

          const gasPrice = await this.$web3.eth.getGasPrice()

          const gas = await contract.methods.postItemForSell(
            this.postItemForSellParams.landId, 
            itemNameBytes, 
            this.postItemForSellParams.itemType, 
            itemPriceWei, 
            priceIncrRateBasis, 
            this.postItemForSellParams.priceIncrNItems, 
            this.postItemForSellParams.totalItems, 
            this.postItemForSellParams.contentUrl).estimateGas()
         
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.postItemForSell(
            this.postItemForSellParams.landId, 
            itemNameBytes, 
            this.postItemForSellParams.itemType, 
            itemPriceWei, 
            priceIncrRateBasis, 
            this.postItemForSellParams.priceIncrNItems, 
            this.postItemForSellParams.totalItems, 
            this.postItemForSellParams.contentUrl).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get mintFaucetsParams(): MintFaucetsParams {
    return this.formRepo.getAttr(this.form_name, 'mint_faucets_params') as MintFaucetsParams || newMintFaucetsParams()
  }
  
  set mintFaucetsParams(val: MintFaucetsParams) {
    this.formRepo.updateAttr(this.form_name, 'mint_faucets_params', val)
  }

  get mintFaucets(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `mint_faucets`,
      onCallSuccess: this.mintFaucetsParams.success,
      onCallError: this.mintFaucetsParams.err,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.BUSD)
        const contract = this.getFaucetsContract(addr)

        try {
          const amountOutWei = toWei(`${this.mintFaucetsParams.amountOut}`)

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.mint(amountOutWei).estimateGas()
          
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.mint(amountOutWei).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get mintEmptyLandParams(): BuyMetaLandWithCityTokenParams {
    return this.formRepo.getAttr(this.form_name, 'metaland_mint_params') as BuyMetaLandWithCityTokenParams || newBuyMetaLandWithCityTokenParams()
  }
  
  set mintEmptyLandParams(val: BuyMetaLandWithCityTokenParams) {
    this.formRepo.updateAttr(this.form_name, 'metaland_mint_params', val)
  }

  get mintEmptyLand(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `metaland_mint`,
      onCallSuccess: this.mintEmptyLandParams.success,
      onCallError: this.mintEmptyLandParams.err,
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.MetaCity)
        const contract = this.getMetaCityContract(addr)

        try {
          const amountInWei = toWei(`${this.mintEmptyLandParams.amountIn}`)
          const businessNameBytes = toBytes32(this.mintEmptyLandParams.businessName)
          const landId = this.mintEmptyLandParams.landId
          const businessType = this.mintEmptyLandParams.businessType

          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.mintEmptyLand(
            landId, 
            amountInWei, 
            businessNameBytes, 
            businessType).estimateGas()
         
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.mintEmptyLand(
            landId, 
            amountInWei, 
            businessNameBytes, 
            businessType).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get buyNativeTokenWithCityTokenParams(): BuyNativeTokenWithCityTokenParams {
    return this.formRepo.getAttr(this.form_name, 'buy_native_token_params') as BuyNativeTokenWithCityTokenParams || newBuyNativeTokenWithCityTokenParams()
  }
  
  set buyNativeTokenWithCityTokenParams(val: BuyNativeTokenWithCityTokenParams) {
    this.formRepo.updateAttr(this.form_name, 'buy_native_token_params', val)
  }

  get buyNativeTokenWithCityToken(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `buy_native_token`,
      onCallSuccess: this.buyNativeTokenWithCityTokenParams.success,
      onCallError: this.buyNativeTokenWithCityTokenParams.err,
      handle: async () => {
        
        const addr = getAddressOfContract(ContractNames.BuyCity)
        const contract = this.getBuyCityContract(addr)
        const tokenName = toBytes32(this.buyNativeTokenWithCityTokenParams.tokenName)

        try {
          const amountInWei = toWei(`${this.buyNativeTokenWithCityTokenParams.amount}`)
          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.buyNative(tokenName, amountInWei).estimateGas()
         
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.buyNative(tokenName, amountInWei).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get buyCityTokenWithNativeTokenParams(): BuyCityTokenWithNativeTokenParams {
    return this.formRepo.getAttr(this.form_name, 'city_mint_params') as BuyCityTokenWithNativeTokenParams || newBuyCityTokenWithNativeTokenParams()
  }
  
  set buyCityTokenWithNativeTokenParams(val: BuyCityTokenWithNativeTokenParams) {
    this.formRepo.updateAttr(this.form_name, 'city_mint_params', val)
  }

  get buyCityTokenWithNativeToken(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `city_mint`,
      onCallSuccess: this.buyCityTokenWithNativeTokenParams.success,
      onCallError: this.buyCityTokenWithNativeTokenParams.err,
      handle: async () => {
        
        const addr = getAddressOfContract(ContractNames.BuyCity)
        const contract = this.getBuyCityContract(addr)
        const tokenName = toBytes32(this.buyCityTokenWithNativeTokenParams.tokenName)

        try {
          const amountInWei = toWei(`${this.buyCityTokenWithNativeTokenParams.amount}`)
          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await contract.methods.buyCity(tokenName, amountInWei).estimateGas()
         
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await contract.methods.buyCity(tokenName, amountInWei).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get approveERC20TokenParams(): ApproveERC20TokenParams {
    return this.formRepo.getAttr(this.form_name, 'approve_erc20_token_params') as ApproveERC20TokenParams || newApproveERC20TokenParams()
  }
  
  set approveERC20TokenParams(val: ApproveERC20TokenParams) {
    this.formRepo.updateAttr(this.form_name, 'approve_erc20_token_params', val)
  }

  get approveERC20Token(): ContractLoader {

    return new ContractLoader(this, (_: any) => ({
      stateKey: `approve_erc20_${this.approveERC20TokenParams.tokenName}_for_${this.approveERC20TokenParams.approveContractAddr.toLowerCase()}`,
      onCallSuccess: this.approveERC20TokenParams.success,
      onCallError: this.approveERC20TokenParams.err,
      handle: async () => {
        const addr = this.getERC20TokenAddr(this.approveERC20TokenParams.tokenName)
        const erc20Token = this.getERC20TokenContract(addr)
        const contractAddr = this.approveERC20TokenParams.approveContractAddr

        try {
          let amount = toWei(`${this.approveERC20TokenParams.amount}`)
          const gasPrice = await this.$web3.eth.getGasPrice()
          const gas = await erc20Token.methods.approve(contractAddr, amount).estimateGas()
          const params = {
            gas: gas,
            gasPrice: gasPrice
          }

          const res = await erc20Token.methods.approve(contractAddr, amount).send(params)
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get allowanceOfERC20TokenParams(): AllowanceOfERC20TokenParams {
    return this.formRepo.getAttr(this.form_name, 'allowance_of_erc20_token_params') as AllowanceOfERC20TokenParams || newAllowanceOfERC20TokenParams()
  }

  set allowanceOfERC20TokenParams(val: AllowanceOfERC20TokenParams) {
    this.formRepo.updateAttr(this.form_name, 'allowance_of_erc20_token_params', val)
  }

  get allowanceOfERC20Token(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: `allowance_of_${this.allowanceOfERC20TokenParams.tokenName}_for_${this.allowanceOfERC20TokenParams.contractAddr.toLowerCase()}`,
      handle: async () => {
        const addr = this.getERC20TokenAddr(this.allowanceOfERC20TokenParams.tokenName)
        const erc20Token = this.getERC20TokenContract(addr)
        const contractAddr = this.allowanceOfERC20TokenParams.contractAddr

        try {
          const res = await erc20Token.methods.allowance(this.walletAddress, contractAddr).call()
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  get balanceOfCityToken(): ContractLoader {
    return new ContractLoader(this, (_: any) => ({
      stateKey: 'city_token_balance',
      handle: async () => {
        const addr = getAddressOfContract(ContractNames.BUSD)
        const cityToken = this.getERC20TokenContract(addr)

        try {
          const res = await cityToken.methods.balanceOf(this.walletAddress).call()
          return res
        } catch (err: any) {
          const errmsg = this.$web3Helper.errorMessage(err)
          throw new Error(errmsg)
        }
      }
    }))
  }

  getBuyCityContract(addr: string): BuyCity {
    return new this.$web3.eth.Contract(BuyCityContract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as BuyCity
  }

  getMetaMarketContract(addr: string): MetaMarket {
    return new this.$web3.eth.Contract(MetaMarketContract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as MetaMarket
  }

  getSecondaryMarketContract(addr: string): SecondaryMarket {
    return new this.$web3.eth.Contract(SecondaryMarketContract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as SecondaryMarket
  }

  getMetaTweetContract(addr: string): MetaTweet {
    return new this.$web3.eth.Contract(MetaTweetContract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as MetaTweet
  }

  getFaucetsContract(addr: string): Faucets {
    return new this.$web3.eth.Contract(FaucetsContract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as Faucets
  }

  getMetaCityContract(addr: string): MetaCity {
    return new this.$web3.eth.Contract(MetaCityContract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as MetaCity
  }

  getMetaLandContract(addr: string): MetaLand {
    return new this.$web3.eth.Contract(MetaLandContract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as MetaLand
  }

  getMetaItemContract(addr: string): MetaItem {
    return new this.$web3.eth.Contract(MetaItemContract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as MetaItem
  }

  getERC20TokenAddr(tokenName: string): string {
    let addr: string = ''
    switch (tokenName.toUpperCase()) {
      case 'BUSD':
        addr = getAddressOfContract(ContractNames.BUSD)
        break
      // case 'USDT':
      //   addr = getAddressOfContract(ContractNames.USDT)
      //   break
      // case 'USDC':
      //   addr = getAddressOfContract(ContractNames.USDC)
      //   break
    }
    return addr
  }

  getERC20TokenContract(addr: string): ERC20 {
    return new this.$web3.eth.Contract(ERC20Contract.abi as AbiItem[], addr, {
      from: this.walletAddress
    }) as any as ERC20
  }

  // Programmer = 1
  // Designer = 2
  // Marketer = 3
  // Creative = 4
  // Business = 5
  // PropertyDeveloper = 6
  get allOccupations(): IOption[] {
    return [
      {
        value: Occupation.Programmer,
        label: 'Programmer'
      },
      {
        value: Occupation.Designer,
        label: 'Designer'
      },
      {
        value: Occupation.Marketer,
        label: 'Marketer'
      },
      {
        value: Occupation.Creative,
        label: 'Creative'
      },
      {
        value: Occupation.Business,
        label: 'Business Person'
      },
      {
        value: Occupation.PropertyDeveloper,
        label: 'Property Developer'
      },
    ]
  }

  // Residence & Apartment = 1
  // Interior Design = 2
  // Branded Consumer Products = 3
  // Art & Photo Gallery = 4
  // Book & Content Publisher = 5
  // Clip & Music Production = 6
  // Mall & Outlet = 7
  get allBusinessTypes(): IOption[] {
    return [
      {
        value: BusinessType.ResidenceAndApartment,
        label: 'Residence & Apartment'
      },
      {
        value: BusinessType.InteriorDesign,
        label: 'Interior Design'
      },
      {
        value: BusinessType.BrandedConsumerProducts,
        label: 'Branded Consumer Products'
      },
      {
        value: BusinessType.ArtAndPhotoGallery,
        label: 'Art & Photo Gallery'
      },
      {
        value: BusinessType.BookAndContentPublisher,
        label: 'Book & Content Publisher'
      },
      {
        value: BusinessType.ClipAndMusicProduction,
        label: 'Clip & Music Production'
      },
      {
        value: BusinessType.MallAndOutlet,
        label: 'Mall & Outlet'
      },
    ]
  }

  get expiredNDaysOptions(): IOption[] {
    const options = []
    for (let i=1; i<=365; i++) {
      options.push({
        value: `${i}`,
        label: `${i} days`
      })
    }
    return options
  }

  capitalByCountry(country: string): string {
    const capitals = {
      'thailand': 'bangkok',
      'vietnam': 'ho-chi-minh',
      'india': 'new-delhi',
      'pakistan': 'islamabad',
      'ukraine': 'kyiv',
      'kenya': 'nairobi',
      'nigeria': 'abuja',
      'venezuela': 'caracas',
      'usa': 'washington',
      'argentina': 'buenos-aires',
      'colombia': 'bogota',
      'china': 'beijing',
      'brazil': 'brasilia',
      'philippines': 'manila',
      'south-africa': 'johannesburg',
      'ghana': 'accra',
      'russia': 'moscow',
      'tanzania': 'dar-es-salaam',
      'afghanistan': 'kabul',
      'singapore': 'singapore',
      'togo': 'lome',
    }

    return capitals[country.toLowerCase()]
  }

  get allCountries(): IOption[] {
    return [
      // {
      //   value: 'afghanistan',
      //   label: 'Afghanistan',
      // },
      {
        value: 'argentina',
        label: 'Argentina',
      },
      {
        value: 'brazil',
        label: 'Brazil',
      },
      {
        value: 'china',
        label: 'China',
      },
      {
        value: 'colombia',
        label: 'Colombia',
      },
      {
        value: 'ghana',
        label: 'Ghana',
      },
      {
        value: 'india',
        label: 'India',
      },
      {
        value: 'kenya',
        label: 'Kenya',
      },
      {
        value: 'nigeria',
        label: 'Nigeria',
      },
      {
        value: 'pakistan',
        label: 'Pakistan',
      },
      {
        value: 'philippines',
        label: 'Philippines',
      },
      {
        value: 'russia',
        label: 'Russia',
      },
      {
        value: 'singapore',
        label: 'Singapore',
      },
      {
        value: 'south-africa',
        label: 'South Africa',
      },
      {
        value: 'tanzania',
        label: 'Tanzania',
      },
      {
        value: 'thailand',
        label: 'Thailand',
      },
      {
        value: 'togo',
        label: 'Togo',
      },
      {
        value: 'ukraine',
        label: 'Ukraine',
      },
      {
        value: 'usa',
        label: 'USA',
      },
      {
        value: 'venezuela',
        label: 'Venezuela',
      },
      {
        value: 'vietnam',
        label: 'Vietnam',
      },
    ]
  }
}