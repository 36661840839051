




























import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import { fromWei, getChainId, toWei } from '~/utils/util'
import {
  FormMode,
  ItemType,
  ListMode,
} from './models'
import { WaitGroup } from '~/utils/WaitGroup'

@Component({
  components: {
  },
})
export default class AcceptItemBuyOrder extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  isAllowanceApproved: boolean = true

  checkAllowance: () => void

  created() {
    this.checkAllowance = _debounce(() => {
      this._onCheckAllowance()
    }, 300)
  }

  async onCheckAllowance() {
    const form = this.form

    this.allowanceOfMetaItemForSecondaryMarketParams.itemId = form.acceptItemId
    const getAllowance = this.allowanceOfMetaItemForSecondaryMarket
    await getAllowance.call()

    const res = getAllowance.callItems[0]
    this.isAllowanceApproved = (res == true)
  }

  async _onCheckAllowance() {
    this.onCheckAllowance()
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: any, oldVal: any) {
    this.checkAllowance()
  }

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    if (!this.isAllowanceApproved) {
    this.beginTransaction()
      this.approveMetaItemForSecondaryMarketParams.itemId = value.acceptItemId
      this.approveMetaItemForSecondaryMarketParams.success = this.endTransactionApprove
      this.approveMetaItemForSecondaryMarketParams.err = () => { this.endContractTransactionError(this.approveMetaItemForSecondaryMarket) }
      this.approveMetaItemForSecondaryMarket.call()
    } else {
      this.onAcceptBuyOrder(value)
    }
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransactionApprove() {
    this.isLoading = false
    // send transaction after approve
    this.onAcceptBuyOrder(this.form)
  }

  endTransactionSell() {
    this.isLoading = false

    const reloadCityGrid = async () => {
      this.cityCellRefreshParams.landId = this.selectedCell.id
      await this.cityCellRefresh.call()
      this.cityGridLoader.call()
    }
    reloadCityGrid()
    
    this.balanceOfCityToken.call()
    
    this.onCloseFormClick()

    this.listMode = ListMode.None
  }

  onAcceptBuyOrder(value: FormData) {
    this.beginTransaction()
    this.acceptItemBuyOrderParams.itemId = value.acceptItemId
    this.acceptItemBuyOrderParams.buyOrderAutonumber = value.acceptItemBuyOrderAutonumber
    this.acceptItemBuyOrderParams.sellPrice = value.acceptItemPrice
    this.acceptItemBuyOrderParams.success = this.endTransactionSell
    this.acceptItemBuyOrderParams.err = () => { this.endContractTransactionError(this.acceptItemBuyOrder) }
    this.acceptItemBuyOrder.call()
  }

  get acceptItemBuyOrderFormOptions(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'acceptItemId',
          label: 'Item Id',
          disabled: true,
          defaultValue: this.selectedMyNFT.item_id,
          rules: [RuleHelper.required]
        }
      },
       {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'acceptItemBuyOrderAutonumber',
          label: 'Buy Order Id',
          disabled: true,
          defaultValue: this.selectedMyNFT.buy_order_autonumber,
          rules: [RuleHelper.required]
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '6',
        props: {
          name: 'acceptItemPrice',
          label: 'Sell Price',
          defaultValue: fromWei(this.selectedMyNFT.buy_price),
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '6',
        props: {
          name: 'acceptItemWithToken',
          placeholder: '',
          defaultValue: 'BUSD',
          className: 'select-token',
          disabled: true,
          rules: [RuleHelper.required],
          options: [
            {
              value: 'BUSD',
              label: 'BUSD'
            }
          ]
        }
      },
    ]
  }
}

interface FormData {
  acceptItemId: number
  acceptItemBuyOrderAutonumber: number
  acceptItemPrice: string
  acceptItemWithToken: string
}

