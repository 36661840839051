




import { BaseApp } from '~/core/BaseApp'
import City from '~/features/City.vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: { City },
})
export default class IndexPage extends BaseApp {
}
