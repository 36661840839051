import { 
  ItemType,
  Occupation, 
} from '../features/models'
import { 
  LandInfo,
  MarketItem,
  CitizenInfo,
  TweetItem,
  BuyOrder,
  SellOrder,
} from '../types/meta_city'
import { 
  fromBytes32, 
  fromWei,
  fromBasis
} from './util'

export class MetaCityHelper {

  static parseOccupation(occupationId: number): string {
    switch (occupationId) {
      case Occupation.Programmer: return 'Programmer'
      case Occupation.Designer: return 'Designer'
      case Occupation.Creative: return 'Creative'
      case Occupation.Business: return 'Business'
      case Occupation.Marketer: return 'Marketer'
      case Occupation.PropertyDeveloper: return 'Developer'
    }
    return 'Unknown'
  }

  static parseItemTypeName(itemType: number): string {
    switch (itemType) {
      case ItemType.Avatar: return 'Avatar'
      case ItemType.BrandedProduct: return 'Product'
      case ItemType.Building: return 'Building'
      case ItemType.Coupon: return 'Coupon'
      case ItemType.Ticket: return 'Ticket'
    }
    return 'Unknown'
  }

  static transformSellOrder(sellOrder: [string, string, string, string, string, boolean, boolean, string]): SellOrder {
    const landId = sellOrder[0]
    const autonumber = sellOrder[1]
    const price = sellOrder[2]
    const endedBlock = sellOrder[3]
    const sellerAddr = sellOrder[4]
    const completed = sellOrder[5]
    const deleted = sellOrder[6]
    const createdAt = sellOrder[7]
    
    return {
      land_id: landId,
      autonumber: Number(autonumber),
      price: price,
      ended_block: Number(endedBlock),
      seller_addr: sellerAddr,
      completed: completed,
      deleted: deleted,
      created_at: Number(createdAt),
    }
  }

  static transformBuyOrder(buyOrder: [string, string, string, string, string, string, string, boolean, boolean, boolean, string]): BuyOrder {
    const landId = buyOrder[0]
    const autonumber = buyOrder[1]
    const price = buyOrder[2]
    const endedBlock = buyOrder[3]
    const buyerAddr = buyOrder[4]
    const businessName = buyOrder[5]
    const businessType = buyOrder[6]
    const completed = buyOrder[7]
    const deleted = buyOrder[8]
    const overrided = buyOrder[9]
    const createdAt = buyOrder[10]
    
    return {
      land_id: landId,
      autonumber: Number(autonumber),
      price: price,
      ended_block: Number(endedBlock),
      buyer_addr: buyerAddr,
      business_name: fromBytes32(businessName),
      business_type: Number(businessType),
      completed: completed,
      deleted: deleted,
      overrided: overrided,
      created_at: Number(createdAt),
    }
  }

  static transformCitizenInfo(citizen: [string, string, string, string]): CitizenInfo {
    const name = citizen[0]
    const occupation = citizen[1]
    const avatarItemId = citizen[2]
    const avatarContentUrl = citizen[3]

    return {
      name: fromBytes32(name),
      occupation: Number(occupation),
      avatar_item_id: Number(avatarItemId),
      avatar_content_url: avatarContentUrl,
    }
  }

  static transformTweetItem(item: [string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string]): TweetItem {

    const autonumber = item[0]
    const message = item[1]
    const tweetFrom = item[2]
    const tweetTo = item[3]
    const itemId = item[4]
    const landId = item[5]
    const citizenName = item[6]
    const avatarContentUrl = item[7] 
    const businessName = item[8]
    const businessType = item[9]
    const buildingContentUrl = item[10]
    const itemName = item[11]
    const itemContentUrl = item[12]
    const sellId = item[13]
    const sellItemName = item[14]
    const sellItemContentUrl = item[15]
    const createdAtBlock = item[16]

    return {
      autonumber: Number(autonumber),
      message: message,
      tweet_from: tweetFrom,
      tweet_to: tweetTo,
      item_id: itemId,
      land_id: landId,
      citizen_name: fromBytes32(citizenName),
      avatar_content_url: avatarContentUrl,
      business_name: fromBytes32(businessName),
      business_type: Number(businessType),
      building_content_url: buildingContentUrl,
      item_name: fromBytes32(itemName),
      item_content_url: itemContentUrl,
      sell_id: sellId,
      sell_item_name: fromBytes32(sellItemName),
      sell_content_url: sellItemContentUrl,
      created_at_block: createdAtBlock,
    }
  }

  static transformMarketItem(item: [string, string, string, string, string, string, string, string, string, string, string, string, boolean]): MarketItem {
    const landId = item[0]
    const autonumber = item[1]
    const itemContentUrl = item[2]
    const itemName = item[3]
    const itemType = item[4]
    const initialPrice = item[5]
    const currentPrice = item[6]
    const priceIncrementalRate = item[7]
    const priceIncrementEveryNItems = item[8]
    const totalItems = item[9]
    const lastIncrementCount = item[10]
    const soldItems = item[11]
    const isPaused = item[12]

    return {
      land_id: landId,
      autonumber: Number(autonumber),
      item_content_url: itemContentUrl,
      item_name: fromBytes32(itemName),
      item_type: Number(itemType),
      item_type_name: MetaCityHelper.parseItemTypeName(Number(itemType)),
      initial_price: fromWei(initialPrice),
      current_price: fromWei(currentPrice),
      price_incremental_rate: fromBasis(priceIncrementalRate),
      price_increment_every_n_items: Number(priceIncrementEveryNItems),
      total_items: Number(totalItems),
      last_increment_count: Number(lastIncrementCount),
      sold_items: Number(soldItems),
      is_paused: isPaused,
    }
  }

  static transformLandInfo(info: [string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string]): LandInfo {
    
    const infoLandOwner = info[0]
    const infoBusinessName = info[1]
    const infoBusinessType = info[2]
    const infoBuildingItemId = info[3]
    const infoBuildingContentUrl = info[4]
    const infoSellOrderAutonumber = info[5]
    const infoSellPrice = info[6]
    const infoSellEnded = info[7]
    const infoSellerAddr = info[8]
    const infoBuyOrderAutonumber = info[9]
    const infoBuyPrice = info[10]
    const infoBuyEnded = info[11]
    const infoBuyerAddr = info[12]
    const infoNewBusinessName = info[13]
    const infoNewBusinessType = info[14]
    const infoCurrentPrice = info[15]

    return {
      owner_addr: infoLandOwner,
      business_name: fromBytes32(infoBusinessName),
      business_type: Number(infoBusinessType),
      building_item_id: Number(infoBuildingItemId),
      building_content_url: infoBuildingContentUrl,
      sell_order_autonumber: Number(infoSellOrderAutonumber),
      sell_price: fromWei(infoSellPrice),
      sell_ended_block: Number(infoSellEnded),
      seller_addr: infoSellerAddr,
      buy_order_autonumber: Number(infoBuyOrderAutonumber),
      buy_price: fromWei(infoBuyPrice),
      buy_ended_block: Number(infoBuyEnded),
      buyer_addr: infoBuyerAddr,
      buyer_new_business_name: fromBytes32(infoNewBusinessName),
      buyer_new_business_type: Number(infoNewBusinessType),
      current_price: fromWei(infoCurrentPrice),
    }
  }
}