



































import { Component, Provide, Watch } from 'vue-property-decorator'
import LoginDialog from '~/features/LoginDialog.vue'
import Menu from '~/features/Menu.vue'
import MyLocation from '~/features/MyLocation.vue'
import CityMap from '~/features/CityMap.vue'
import PopOver from '~/features/PopOver.vue'
import Banner from '~/features/Banner.vue'
import BusinessList from '~/features/BusinessList.vue'
import TweetList from '~/features/TweetList.vue'
import BusinessDetail from '~/features/BusinessDetail.vue'
import ItemList from '~/features/ItemList.vue'
import MyAssetList from '~/features/MyAssetList.vue'
import BuyLandForm from '~/features/BuyLandForm.vue'
import BuyItemForm from '~/features/BuyItemForm.vue'
import BidItemForm from '~/features/BidItemForm.vue'
import AcceptItemBuyOrderForm from '~/features/AcceptItemBuyOrderForm.vue'
import SellItemForm from '~/features/SellItemForm.vue'
import CityFilterForm from '~/features/CityFilterForm.vue'
import RegisterCitizenForm from '~/features/RegisterCitizenForm.vue'
import SetupBusinessForm from '~/features/SetupBusinessForm.vue'
import SellBusinessForm from '~/features/SellBusinessForm.vue'
import AcceptBuyOrderForm from '~/features/AcceptBuyOrderForm.vue'
import BuyBusinessForm from '~/features/BuyBusinessForm.vue'
import TweetForm from '~/features/TweetForm.vue'
import Footer from '~/features/Footer.vue'
import { fromWei, getChainId, toWei } from '~/utils/util'
import { CityBaseForm } from './CityBaseForm'
import { FormMode, PopOverMode } from './models'
import { VisitAttention } from '~/consts/alerts'

@Component({
  components: {
    Menu,
    LoginDialog,
    CityMap,
    MyLocation,
    PopOver,
    Banner,
    BusinessList,
    BusinessDetail,
    ItemList,
    MyAssetList,
    TweetList,
    BuyLandForm,
    BuyItemForm,
    BidItemForm,
    AcceptItemBuyOrderForm,
    SellItemForm,
    CityFilterForm,
    RegisterCitizenForm,
    SetupBusinessForm,
    SellBusinessForm,
    AcceptBuyOrderForm,
    BuyBusinessForm,
    TweetForm,
    Footer,
  },
})
export default class City extends CityBaseForm<any> {
  
  @Provide('form_name') form_name = 'city_form'

  created() {
    this.formMode = FormMode.SelectCityForm

    const walletLoadFinish = async () => {
      await this.switchChain(getChainId())
      
      this.myItemsLoader.call()
      this.balanceOfCityToken.call()
      this.citizenInfo.call()
    }
    
    this.walletLoader(walletLoadFinish, walletLoadFinish).call({ reconnect: true })

    // Show visit attention first time when page loaded
    if (process.client) {
      const showVisitAttention = this.formRepo.getAttr(this.form_name, 'show_visit_attention')
      if (!showVisitAttention) {
        this.alert(VisitAttention(() => {
          if (this.$device.isMobile) {
            return
          }
          this.popOverMode = PopOverMode.Banner
        }))
        this.formRepo.updateAttr(this.form_name, 'show_visit_attention', true)
      }
    }
  }
}

