







































import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import { ArrayHelper } from '~/utils/ArrayHelper'
import { 
  FormMode,
  ListMode,
  MapCell,
} from './models'
import { Route } from 'vue-router'
import RuleHelper from '~/utils/RuleHelper'
import html2canvas from 'html2canvas'

@Component({
  components: {
  },
})
export default class CityFilterForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  onMinimapCellClick(cell: MapCell) {
    const param = this.cityGridParam
    this.$router.push({ path: `/${param.country}/${param.city}/${cell.id}/` })
  }

  onTakeScreenshotClick() {
    html2canvas(document.querySelector('#grid'), { useCORS: false, proxy: '/_proxy' })
    .then((canvas) => {
      this.saveScreenshotAs(canvas.toDataURL(),  'my_metacity.png')
    })
  }

  saveScreenshotAs(uri: any, filename: any) {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;

      //Firefox requires the link to be in the body
      document.body.appendChild(link);

      //simulate click
      link.click();

      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  async onRouteChange(newVal: Route) {
    if (newVal.name != 'country-city-block') {
      return
    }

    const country = newVal.params.country
    const city = newVal.params.city
    const block = Number(newVal.params.block)

    this.formRepo.updateAttr(this.form_name, 'country', `${country}`)
    this.formRepo.updateAttr(this.form_name, 'city', `${city}`)
    this.formRepo.updateAttr(this.form_name, 'block', `${block}`)

    this.cityGridParam = {
      country: country,
      city: city,
      block: block,
    }

    const reloadCityGrid = async () => {
      await this.currentBlock.call()
      await this.cityGridLoader.call()
      this.citiesLoader.call()
      this.miniMapLoader.call()
    }
    reloadCityGrid()

    this.listMode = ListMode.None
    this.formMode = FormMode.SelectCityForm
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: FormData, oldVal: FormData) {
    if (oldVal.country == newVal.country &&
        oldVal.block == newVal.block && 
        oldVal.city == newVal.city) {
      return
    }

    this.cityGridParam = {
      ...this.cityGridParam,
      country: newVal.country,
      city: newVal.city,
      block: newVal.block || 0,
    }

    this.formMode = FormMode.SelectCityForm
    this.listMode = ListMode.None
  }

  get minimapCells(): MapCell[] {
    const cityMaps = this.miniMapLoader.callItems
    if (!cityMaps || cityMaps.length <= 0) {
      return []
    }

    // Build minimap cells from city map api
    const cells = []
    const cityMap = cityMaps[0]
    let i = -1
    for (let block in cityMap.blocks) {
      i++
      const cityBlock = cityMap.blocks[block]
      cells.push({
        id: `${i}`,
        extras: [],
      })
    }

    return cells
  }

  get cityBlocks(): IOption[] {
    const blocks = []
    for (let i=0; i<16; i++) {
      blocks.push({
        label: `${i+1}`,
        value: `${i}`
      })
    }
    return blocks
  }

  get filterCityForm(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.AUTOCOMPLETE,
        col: '12',
        props: {
          name: 'country',
          label: 'SELECT COUNTRY',
          placeholder: 'Select Country',
          className: 'select-country',
          clearable: false,
          rules: [RuleHelper.required],
          options: this.allCountries,
          on: {
            change: (newVal: string) => {
              if (newVal == null || newVal.length == 0) {
                return
              }
              const capitalCity = this.capitalByCountry(newVal)
              this.$router.push({ path: `/${newVal}/${capitalCity}/0/` })
            }
          }
        }
      },
      {
        type: INPUT_TYPES.AUTOCOMPLETE,
        col: '12',
        props: {
          name: 'city',
          label: 'SELECT CITY & ZONE',
          placeholder: 'Select City',
          className: 'select-city',
          clearable: false,
          rules: [RuleHelper.required],
          options: ArrayHelper.toOptions(this.citiesLoader.callItems, 'alias', 'name'),
          on: {
            change: (newVal: string) => {
              if (newVal == null || newVal.length == 0){
                return
              }
              const param = this.cityGridParam
              this.$router.push({ path: `/${param.country}/${newVal}/${param.block}/` })
            }
          },
        },
      },
    ]
  }

  calMinimapCellClass(cell: MapCell) {
    const classes = {
      'minimap-cell': true,
    }
    if (cell.id == `${this.cityGridParam.block}`) {
      classes['active'] = true
    } else {
      classes['active'] = false
    }
    return classes
  }

  isCurrentSelectedCell(cell: MapCell): boolean {
    return cell.id == `${this.cityGridParam.block}`
  }
}

interface FormData {
  country: string
  city: string
  block: number
}
