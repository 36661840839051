





























import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { fromWei, getChainId, toBN, toRounded, toWei } from '~/utils/util'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import {
  FormMode,
  BusinessType,
} from './models'
import { 
    getAddressOfContract, 
    ContractNames
} from '~/consts/config'
import { WaitGroup } from '~/utils/WaitGroup'

@Component({
  components: {
  },
})
export default class BuyLandForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  isAllowanceApproved: boolean = true

  checkAllowance: () => void

  created() {
    this.checkAllowance = _debounce(() => {
      this._onCheckAllowance()
    }, 300)
  }

  async onCheckAllowance() {
    const form = this.form
    if (!form.mintAmountIn || form.mintAmountIn.length == 0) {
      this.isAllowanceApproved = true
      return
    }

    this.allowanceOfERC20TokenParams.tokenName = 'BUSD'
    this.allowanceOfERC20TokenParams.contractAddr = getAddressOfContract(ContractNames.MetaCity)
    const getAllowance = this.allowanceOfERC20Token
    await getAllowance.call()

    const res = `${getAllowance.callItems[0]}`
    const allowance = toBN(res)
    this.isAllowanceApproved = allowance.gte(toWei(form.mintAmountIn))
  }

  async _onCheckAllowance() {
    this.onCheckAllowance()
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: any, oldVal: any) {
    this.checkAllowance()
  }

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    if (!this.isAllowanceApproved) {
      this.beginTransaction()
      this.approveERC20TokenParams.tokenName = 'BUSD'
      this.approveERC20TokenParams.approveContractAddr = getAddressOfContract(ContractNames.MetaCity)
      this.approveERC20TokenParams.success = this.endTransactionApprove
      this.approveERC20TokenParams.err = () => { this.endContractTransactionError(this.approveERC20Token) }
      this.approveERC20Token.call()
    } else {
      this.onMintEmptyLand(value)
    }
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransactionApprove() {
    this.isLoading = false
    this.checkAllowance()
    this.onMintEmptyLand(this.form)
  }

  endTransactionBuy() {
    this.isLoading = false

    const reloadCityGrid = async () => {
      this.cityCellRefreshParams.landId = this.selectedCell.id
      await this.cityCellRefresh.call()
      this.cityGridLoader.call()
    }
    reloadCityGrid()
    
    this.balanceOfCityToken.call()
    this.onCloseFormClick()
  }

  onMintEmptyLand(value: FormData) {
    this.beginTransaction()
    this.mintEmptyLandParams.landId = this.selectedCell.id
    this.mintEmptyLandParams.businessName = value.businessName
    this.mintEmptyLandParams.businessType = value.businessType
    this.mintEmptyLandParams.amountIn = value.mintAmountIn
    this.mintEmptyLandParams.success = this.endTransactionBuy
    this.mintEmptyLandParams.err = () => {  this.endContractTransactionError(this.mintEmptyLand) }
    this.mintEmptyLand.call()
  }

  get buyLandFormOptions(): IFormOption[] {
    // Will round the decimal to the nerest integer number
    const landCurPriceFull = fromWei(this.currentLandPrice.callItems[0])
    let landCurPriceRounded = toRounded(landCurPriceFull)

    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'mintLandId',
          label: 'Land Id',
          defaultValue: this.selectedCell.id,
          rules: [RuleHelper.required],
          disabled: true,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '6',
        props: {
          name: 'mintAmountIn',
          label: 'Base Price',
          defaultValue: landCurPriceRounded,
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '6',
        props: {
          name: 'mintWithToken',
          placeholder: '',
          defaultValue: 'BUSD',
          className: 'select-token',
          rules: [RuleHelper.required],
          options: [
            {
              value: 'BUSD',
              label: 'BUSD'
            }
          ]
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'businessName',
          label: 'My Brand',
          rules: [RuleHelper.required]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'businessType',
          label: 'My Business',
          placeholder: '',
          className: 'select-business-type',
          rules: [RuleHelper.required],
          options: this.allBusinessTypes,
        }
      },
    ]
  }
}

interface FormData {
  mintLandId: string
  mintAmountIn: string
  mintWithToken: string
  businessName: string
  businessType: BusinessType
}
