




































import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { fromWei, getChainId, toWei, toBN } from '~/utils/util'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import { 
    getAddressOfContract, 
    ContractNames
} from '~/consts/config'

@Component({
  components: {
  },
})
export default class SetupBusinessForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  isAllowanceApproved: boolean = true

  checkAllowance: () => void

  created() {
    this.checkAllowance = _debounce(() => {
      this._onCheckAllowance()
    }, 300)
  }

  async onCheckAllowance() {
    const form = this.form
    if (!form.setupFee || form.setupFee.length == 0) {
      this.isAllowanceApproved = true
      return
    }

    this.allowanceOfERC20TokenParams.tokenName = 'BUSD'
    this.allowanceOfERC20TokenParams.contractAddr = getAddressOfContract(ContractNames.MetaCity)
    const getAllowance = this.allowanceOfERC20Token
    await getAllowance.call()

    const res = `${getAllowance.callItems[0]}`
    const allowance = toBN(res)
    this.isAllowanceApproved = allowance.gte(toWei(form.setupFee))
  }

  async _onCheckAllowance() {
    this.onCheckAllowance()
  }

  get selectedNFTUrl(): string {
    return this.formRepo.getAttr(this.form_name, 'buildingItemContentUrl')
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: any, oldVal: any) {
    this.checkAllowance()
  }

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    if (!this.isAllowanceApproved) {
      this.beginTransaction()
      this.approveERC20TokenParams.tokenName = 'BUSD'
      this.approveERC20TokenParams.approveContractAddr = getAddressOfContract(ContractNames.MetaCity)
      this.approveERC20TokenParams.success = this.endTransactionApprove
      this.approveERC20TokenParams.err = () => { this.endContractTransactionError(this.approveERC20Token) }
      this.approveERC20Token.call()
    } else {
      this.onSetupBusiness(value)
    }
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransactionApprove() {
    this.isLoading = false
    this.checkAllowance()
    this.onSetupBusiness(this.form)
  }

  endTransactionSetup() {
    this.isLoading = false

    const reloadCityGrid = async () => {
      this.cityCellRefreshParams.landId = `${this.form.businessLandId}`
      await this.cityCellRefresh.call()
      this.cityGridLoader.call()
    }
    reloadCityGrid()

    this.balanceOfCityToken.call()
    this.onCloseFormClick()
  }

  onSetupBusiness(value: FormData) {
    this.beginTransaction()
    this.setupBusinessParams.fee = value.setupFee
    this.setupBusinessParams.landId = value.businessLandId
    this.setupBusinessParams.businessName = value.businessName
    this.setupBusinessParams.businessType = value.businessType
    this.setupBusinessParams.buildingItemId = value.buildingItemId
    this.setupBusinessParams.success = this.endTransactionSetup
    this.setupBusinessParams.err = () => {  this.endContractTransactionError(this.setupBusiness) }
    this.setupBusiness.call()
  }

  get setupBusinessFormOptions(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'businessLandId',
          label: 'Land Id',
          disabled: true,
          defaultValue: this.selectedBusinessCell.id,
          rules: [RuleHelper.required]
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'businessName',
          label: 'My Brand',
          defaultValue: this.selectedBusinessCell.business_name,
          rules: [RuleHelper.required]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'businessType',
          label: 'My Business',
          placeholder: '',
          className: 'select-business-type',
          rules: [RuleHelper.required],
          defaultValue: this.selectedBusinessCell.business_type,
          options: this.allBusinessTypes,
        }
      },
      {
        type: INPUT_TYPES.TEXT,
        col: '6',
        props: {
          name: 'setupFee',
          label: 'City Fee',
          defaultValue: '3',
          disabled: true,
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '6',
        props: {
          name: 'setupWithToken',
          placeholder: '',
          defaultValue: 'BUSD',
          className: 'select-token',
          disabled: true,
          rules: [RuleHelper.required],
          options: [
            {
              value: 'BUSD',
              label: 'BUSD'
            }
          ]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'buildingItemId',
          label: 'Building NFT',
          explain: 'Buy NFT from seller in MetaCity to use as your building',
          placeholder: '',
          defaultValue: '',
          className: 'select-item',
          options: this.buildingNFTOptionItems,
          on: {
            change: (newVal: any) => {
              for (let i=0; i<this.myItemsLoader.callItems.length; i++) {
                const item = this.myItemsLoader.callItems[i]
                if (item.item_id == newVal) {
                  this.formRepo.updateAttr(this.form_name, 'buildingItemContentUrl', item.item_content_url)
                  break
                }
              }
            }
          }
        }
      },
    ]
  }

  get buildingNFTOptionItems(): IOption[] {
    const items: IOption[] = []
    for (let i=0; i<this.myItemsLoader.callItems.length; i++) {
      const item = this.myItemsLoader.callItems[i]
      items.push({
        value: item.item_id,
        label: item.item_name,
      })
    }
    return items
  }
}

interface FormData {
  businessLandId: number
  businessName: string
  businessType: number
  setupFee: string
  setupWithToken: string
  buildingItemId: string
}
