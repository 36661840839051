


























import { Component, Model, Prop, Watch } from 'vue-property-decorator'
import { DefaultAvatarURL, getPathWithBaseURL } from '~/consts/config';
import { BaseApp } from '~/core/BaseApp'
import { isAllowImageUrl } from '~/utils/util';
import { CityBaseForm } from './CityBaseForm';
import { FormMode, PopOverItem, PopOverItemType } from './models';

@Component
export default class PopOver extends CityBaseForm<any> {

  onActionButtonClick(item: PopOverItem) {
    if (item.item_type == PopOverItemType.ItemOutOfStock) {
      this.formMode = FormMode.BidItemForm
    } else {
      if (this.landLocationLoader.callItems.length == 0) {
        this.closePopOver()
        return
      }
      const location = this.landLocationLoader.callItems[0]
      this.$router.push({ path: `/${location.country_alias}/${location.city_alias}/${location.block}/` })
    }

    this.closePopOver()
  }

  onSecondActionButtonClick(item: PopOverItem) {
    if (item.item_type == PopOverItemType.MyItemWithBuyOrder) {
      this.formMode = FormMode.AcceptItemBuyOrderForm
    }

    this.closePopOver()
  }

  isShowSecondButton(item: PopOverItem): boolean {
    return item.item_type == PopOverItemType.MyItemWithBuyOrder
  }

  calSecondButtonName(item: PopOverItem): string {
    if (item.item_type == PopOverItemType.MyItemWithBuyOrder) {
      return 'Accept Bidding'
    }
    return ''
  }

  calGoToBusinessButtonName(item: PopOverItem): string {
    if (item.item_type == PopOverItemType.Business) {
      return 'Go To This Business'
    }
    if (item.item_type == PopOverItemType.SellItem) {
      return 'Go To Business Selling This NFT'
    }
    if (item.item_type == PopOverItemType.MyItem || item.item_type == PopOverItemType.MyItemWithBuyOrder) {
      return 'Go To Creator Of NFT'
    }
    if (item.item_type == PopOverItemType.Avatar) {
      return 'OK'
    }
    if (item.item_type == PopOverItemType.ItemOutOfStock) {
      return 'Place Bid For This NFT'
    }

    return 'Go To This Business'
  }

  calItemImage(item: PopOverItem): string {
    if (isAllowImageUrl(item.item_content_url)) {
      return item.item_content_url
    }
    if (item.item_type == PopOverItemType.Business) {
      if (item.building_type > 0) {
        return getPathWithBaseURL(`/bld${item.building_type}.png`)
      }
    }

    return DefaultAvatarURL
  }

}
