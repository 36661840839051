

























































import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { 
  CityCell,
  FormMode,
  newCityCell,
  newCityGrid,
  ListMode,
} from './models'
import { Route } from 'vue-router'

@Component({
  components: {
  },
})
export default class CityMap extends CityBaseForm<any> {
  @Provide('form_name') form_name = 'city_form'

  static readonly MAX_CITY_BLOCKS: number = 16

  shareToFacebookURL: string = ''
  
  cityGridLoadingStyle: {[key: string]: string} = {}
  cityGridStyle: {[key: string]: string} = {}

  country: string = ''
  city: string = ''
  block: number = 0

  @Watch('$route', { immediate: true, deep: true })
  async onRouteChange(newVal: Route) {
    if (newVal.name != 'country-city-block') {
      return
    }

    this.country = newVal.params.country
    this.city = newVal.params.city
    this.block = Number(newVal.params.block) + 1
  }
  
  created() {
    this.cityGridStyle = {
      ...this.cityGridStyle,
      'grid-template-columns': `repeat(${this.cityGrid.cols}, 50px)`,
      'grid-template-rows': `repeat(${this.cityGrid.rows}, 50px)`,
      'visibility': 'hidden',
    }
    this.cityGridLoadingStyle = {
      'display': 'flex'
    }
  }

  mounted() {
    const grid = this.$refs['grid'] as HTMLElement
    if (grid) {
      this.cityGridStyle = {
        ...this.cityGridStyle,
        'top': `${window.document.documentElement.clientHeight/2 - grid.getBoundingClientRect().height/2-30}px`,
        'left': `${window.document.documentElement.clientWidth/2 - grid.getBoundingClientRect().width/2+160}px`,
        'visibility': 'visible',
      }
      this.cityGridLoadingStyle = {
        'display': 'none'
      }
      this.setupDragableCityGrid()
    }

    const url = window.location.href
    this.shareToFacebookURL = `https://www.facebook.com/sharer/sharer.php?m2w&u=${encodeURIComponent(url)}`
  }

  get cityCells(): CityCell[] {
    const cells = this.cityGrid.cells
    return cells
  }

  setupDragableCityGrid() {
    const grid = this.$refs['grid'] as HTMLElement
    grid.addEventListener("mousedown", e =>{
      const currentGridTop = parseInt(grid.style.top)
      const currentGridLeft = parseInt(grid.style.left)
      const mousemove = (a) => {
        grid.style.top = `${currentGridTop + (a.clientY - e.clientY)}px`
        grid.style.left = `${currentGridLeft + (a.clientX - e.clientX)}px`
      }
      grid.addEventListener("mousemove", mousemove) 
      grid.addEventListener("mouseup", ()=>{
          grid.removeEventListener("mousemove", mousemove);
      })
      grid.addEventListener("mouseleave", ()=>{
          grid.removeEventListener("mousemove", mousemove);
      })   
    })
  }

  onBuildingTooltipMouseEnter(cell: CityCell) {
    if (!cell.is_business) {
      this.hoverCell= newCityCell()
      return
    }

    this.hoverCell = cell
  }

  onBuildingTooltipMouseLeave(cell: CityCell) {
    this.hoverCell= newCityCell()
  }

  onCellClick(cell: CityCell) {

    if (cell.building_type.length == 0) {
      this.selectedBusinessCell = newCityCell()
      this.onCloseListClick()
      
      // Load current price of land id if no building their
      this.currentLandPriceParams.landId = cell.id
      this.currentLandPrice.call()

      this.formMode = FormMode.BuyLandForm
      this.selectedCell = cell
      return
    } 
    
    if (cell.building_type.length > 0 && cell.is_business) {
      // Select business item if there is a building their

      this.formMode = FormMode.SelectCityForm
      // Load business cell
      this.selectedBusinessCell = cell
      this.listMode = ListMode.BusinessDetail

      // Load business owner
      this.businessOwnerInfoParams.ownerAddr = cell.owner_addr
      this.businessOwnerInfo.call()

      // Load purchase history
      this.purchaseHistoryParams.landId = cell.id
      this.purchaseHistory.call()
      
      return
    } else {
      // Other cell
      this.selectedBusinessCell = newCityCell()
      this.onCloseListClick()
      return
    }
  }

  goUp() {
    const curBlock = parseInt(`${this.cityGridParam.block}`)
    let nextBlock = curBlock - 4
    if (nextBlock < 0) {
      nextBlock = CityMap.MAX_CITY_BLOCKS + nextBlock
    }
    const param = this.cityGridParam
    this.$router.push({ path: `/${param.country}/${param.city}/${nextBlock}/` })
  }

  goDown() {
    const curBlock = parseInt(`${this.cityGridParam.block}`)
    let nextBlock: number = curBlock + 4
    if (nextBlock >= CityMap.MAX_CITY_BLOCKS) {
      nextBlock = nextBlock - CityMap.MAX_CITY_BLOCKS 
    }
    const param = this.cityGridParam
    this.$router.push({ path: `/${param.country}/${param.city}/${nextBlock}/` })
  }

  goLeft() {
    const curBlock = parseInt(`${this.cityGridParam.block}`)
    let nextBlock = curBlock - 1
    if (nextBlock < 0) {
      nextBlock = CityMap.MAX_CITY_BLOCKS - 1
    }
    const param = this.cityGridParam
    this.$router.push({ path: `/${param.country}/${param.city}/${nextBlock}/` })
  }

  goRight() {
    const curBlock = parseInt(`${this.cityGridParam.block}`)
    let nextBlock: number = curBlock + 1
    if (nextBlock >= CityMap.MAX_CITY_BLOCKS) {
      nextBlock = 0
    }
    const param = this.cityGridParam
    this.$router.push({ path: `/${param.country}/${param.city}/${nextBlock}/` })
  }

  calCellClass(cell: CityCell): {[key: string]: boolean} {
    const classes = {
      'cell': true,
    }
    if (cell.id == this.selectedCell.id) {
      classes['cell-active'] = true
    }
    return classes
  }

  calCellStyle(cell: CityCell): {[key: string]: string} {
    const refCell = this.refCityCell(cell.id)
    const styles = {}
    if (refCell) {
      if (cell.building_size == 'm') {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '') + 10}`
      } else if (cell.building_size == 'l') {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '') + 19}`
      } else if (cell.building_size == 'road') {
        styles['z-index'] = '1'
      } else {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '')}`
      }
    }
    
    return styles
  }

  refCityCell(cellId: string): Element {
    const grid = this.$refs['grid'] as HTMLElement
    if (!grid) {
      return null
    }
    const cells = grid.querySelectorAll<Element>('.cell') as NodeListOf<Element>
    let selectedCell: Element
    for (let i=0; i<cells.length; i++) {
      if (this.formatCellPos(cells[i].getAttribute('data-key')) == this.formatCellPos(cellId)) {
        selectedCell = cells[i]
        break
      }
    }
    return selectedCell
  }

  calBuildingClass(cell: CityCell): {[key: string]: boolean} {
    const classes = {
      'item': true,
    }
    if (cell.building_size.length > 0) {
      classes[cell.building_size] = true
    }
    if (cell.id == this.hoverCell.id || 
        cell.id == this.selectedCell.id ||
        cell.id == this.selectedBusinessCell.id) {
      classes['item-hover'] = true
    }
    return classes
  }

  calBuildingStyle(cell: CityCell): {[key: string]: string} {
    const refCell = this.refCityCell(cell.id)
    const styles = {}
    if (refCell) {
      if (cell.building_size == 'm') {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '') + 10}`
      } else if (cell.building_size == 'l') {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '') + 19}`
      } else if (cell.building_size == 'road') {
        styles['z-index'] = '1'
      } else {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '')}`
      }
    }

    const buildingImg = this.calBuildingImage(cell)
    if (buildingImg.length > 0) {
      styles['background-image'] = `url(${buildingImg})`
    }
    
    return styles
  }

  calBuildingTooltipClass(cell: CityCell): {[key: string]: boolean} {
    const classes = {
      'item': true,
      'tooltip': true,
    }
    if (cell.building_size.length > 0) {
      classes[cell.building_size] = true
    }
    if (cell.id == this.hoverCell.id || 
        cell.id == this.selectedCell.id ||
        cell.id == this.selectedBusinessCell.id) {
      classes['hover'] = true
    }
    return classes
  }
  
  calBuildingTooltipStyle(cell: CityCell): {[key: string]: string} {
    const refCell = this.refCityCell(cell.id)
    const styles = {}
    if (refCell) {
      if (cell.building_size == 'm') {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '') + 10}`
      } else if (cell.building_size == 'l') {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '') + 19}`
      } else if (cell.building_size == 'road') {
        styles['z-index'] = '1'
      } else {
        styles['z-index'] = `${parseInt(refCell.getBoundingClientRect().top + '')}`
      }
    }
    
    return styles
  }

  formatCellId(landId: string): string {
    return landId.substr(6)
  }

  // return the position of cell in tha map (000 - 224)
  formatCellPos(landId: string): string {
    return landId.substr(9)
  }

}
