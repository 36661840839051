






























import { Component, Provide, Watch } from 'vue-property-decorator'
import { CityBaseForm } from './CityBaseForm'
import { _debounce } from '~/utils/lodash'
import { fromWei, getChainId, toWei, toBN } from '~/utils/util'
import { 
  IFormOption, 
  IOption,
  INPUT_TYPES,
} from '~/components/form/FormTypes'
import RuleHelper from '~/utils/RuleHelper'
import { 
    getAddressOfContract, 
    ContractNames
} from '~/consts/config'
import { ListMode } from './models'

@Component({
  components: {
  },
})
export default class TweetForm extends CityBaseForm<FormData> {
  @Provide('form_name') form_name = 'city_form'

  isAllowanceApproved: boolean = true

  checkAllowance: () => void

  created() {
    this.checkAllowance = _debounce(() => {
      this._onCheckAllowance()
    }, 300)
  }

  async onCheckAllowance() {
    const form = this.form
    if (!form.tweetFee || form.tweetFee.length == 0) {
      this.isAllowanceApproved = true
      return
    }

    this.allowanceOfERC20TokenParams.tokenName = 'BUSD'
    this.allowanceOfERC20TokenParams.contractAddr = getAddressOfContract(ContractNames.MetaTweet)
    const getAllowance = this.allowanceOfERC20Token
    await getAllowance.call()

    const res = `${getAllowance.callItems[0]}`
    const allowance = toBN(res)
    this.isAllowanceApproved = allowance.gte(toWei(form.tweetFee))
  }

  async _onCheckAllowance() {
    this.onCheckAllowance()
  }

  @Watch('form', { deep: true })
  onFormUpdated(newVal: any, oldVal: any) {
    this.checkAllowance()
  }

  onSubmit(value: FormData) {
    if (!this.isLogin) {
      this.$emit('login')
      return
    }

    if (!this.isAllowanceApproved) {
      this.beginTransaction()
      this.approveERC20TokenParams.tokenName = 'BUSD'
      this.approveERC20TokenParams.approveContractAddr = getAddressOfContract(ContractNames.MetaTweet)
      this.approveERC20TokenParams.success = this.endTransactionApprove
      this.approveERC20TokenParams.err = () => { this.endContractTransactionError(this.approveERC20Token) }
      this.approveERC20Token.call()
    } else {
      this.onTweet(value)
    }
  }

  onTransform(value: any) {
    return value
  }

  beginTransaction() {
    this.isLoading = true
  }

  endTransactionApprove() {
    this.isLoading = false
    this.checkAllowance()
    this.onTweet(this.form)
  }

  endTransactionTweet() {
    this.isLoading = false
    
    this.onCloseFormClick()
    this.onClosePopupClick()

    this.listTweets.call()

    this.balanceOfCityToken.call()
    this.listMode = ListMode.TweetList
  }

  onTweet(value: FormData) {
    this.beginTransaction()
    this.postTweetParams.fee = value.tweetFee
    this.postTweetParams.message = value.tweetMessage
    this.postTweetParams.landId = value.tweetMyBusinessId
    this.postTweetParams.sellId = value.tweetMySellItemId
    this.postTweetParams.itemId = value.tweetMyItemId
    this.postTweetParams.success = this.endTransactionTweet
    this.postTweetParams.err = () => {  this.endContractTransactionError(this.setupBusiness) }
    this.postTweet.call()
  }

  // myBusinessListPlusSelectedBusiness is my business + selected business 
  get myBusinessListPlusSelectedBusiness(): IOption[] {
    if (this.selectedBusinessCell.is_business == true) {
      return [
        {
          value: this.selectedBusinessCell.id,
          label: this.selectedBusinessCell.business_name,
        },
        ...this.myBusinessList
      ]
    }
    return this.myBusinessList
  }

  get myBusinessList(): IOption[] {
    const items: IOption[] = []
    for (let i=0; i<this.myBusinessLoader.callItems.length; i++) {
      const item = this.myBusinessLoader.callItems[i]
      items.push({
        value: item.id,
        label: item.business_name,
      })
    }
    return items
  }

  get mySellingNFTs(): IOption[] {
    const items: IOption[] = []
    for (let i=0; i<this.mySellingItems.callItems.length; i++) {
      const item = this.mySellingItems.callItems[i]
      items.push({
        value: item.autonumber,
        label: item.item_name,
      })
    }
    return items
  }

  get myNFTItems(): IOption[] {
    const items: IOption[] = []
    for (let i=0; i<this.myItemsLoader.callItems.length; i++) {
      const item = this.myItemsLoader.callItems[i]
      items.push({
        value: item.item_id,
        label: item.item_name,
      })
    }
    return items
  }

  get tweetFormOptions(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: '12',
        props: {
          name: 'tweetMessage',
          label: 'Tweet Message',
          rules: [RuleHelper.required]
        }
      },{
        type: INPUT_TYPES.TEXT,
        col: '6',
        props: {
          name: 'tweetFee',
          label: 'Meta Tweet Fee',
          defaultValue: '1',
          disabled: true,
          rules: [RuleHelper.required, RuleHelper.decimal]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '6',
        props: {
          name: 'tweetWithToken',
          placeholder: '',
          defaultValue: 'BUSD',
          className: 'select-token',
          disabled: true,
          rules: [RuleHelper.required],
          options: [
            {
              value: 'BUSD',
              label: 'BUSD'
            }
          ]
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'tweetMyBusinessId',
          label: 'Include Land in Meta Tweet',
          placeholder: '',
          defaultValue: '',
          clearable: true,
          className: 'select-item',
          options: this.myBusinessListPlusSelectedBusiness,
          on: {
            change: (landId: string) => {
              this.mySellingItemsParams.landId = landId
              this.mySellingItemsParams.fromAutonumber = 0
              this.mySellingItems.call()
            }
          }
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'tweetMySellItemId',
          label: 'Include Selling NFT in Meta Tweet',
          placeholder: '',
          defaultValue: '',
          clearable: true,
          className: 'select-item',
          options: this.mySellingNFTs,
        }
      },
      {
        type: INPUT_TYPES.SELECT,
        col: '12',
        props: {
          name: 'tweetMyItemId',
          label: 'Include My NFT in Meta Tweet',
          placeholder: '',
          defaultValue: '',
          clearable: true,
          className: 'select-item',
          options: this.myNFTItems,
        }
      },
    ]
  }
}

interface FormData {
  tweetMyBusinessId: string
  tweetMySellItemId: string
  tweetMyItemId: string
  tweetMessage: string
  tweetFee: string
  tweetWithToken: string
}
